export enum OfferSigningStatus {
  PROJECT = 'Projekt Oferty',
  OFFER_CERTIFICATE = 'Certyfikat Oferty',
  LEASE_OFFER_CERTIFICATE = 'Certyfikat Umowy Najmu',
  PROTOCOL = 'Protokół zdawczo-odbiorczy',
  INVOICE = 'Faktura',
}

export enum ConfigType {
  OFFER = 'offer',
  LEASE = 'lease',
  PROJECT = 'project',
  PROTOCOL = 'protocol',
  INVOICE_L = 'invoice_l',
  INVOICE_T = 'invoice_t',
}

export enum OfferSubType {
  INFINITE = 'infinite',
  FIXED_SHORT = 'fixedshort',
  FIXED_LONG = 'fixedlong',
}

export enum LeaseSubType {
  ONE = '1',
  TWO = '2',
  THREE = '3',
}

export enum Titles {
  UMOWA_NIEOKRESLONY = 'Umowa najmu zawarta na czas nieokreślony',
  UMOWA_12_MIESIECY = 'Umowa najmu na czas określony do 12 miesięcy',
  UMOWA_POWYZEJ_12 = 'Umowa najmu na czas określony powyżej 12 miesięcy',
  PROTOCOL = 'Protokół zdawczo-odbiorczy',
  INVOICE = 'Faktura',
}

export enum FilePaths {
  PROJEKT_NIEOKREŚLONY = 'projekt_oferty_na_czas_nieokreslony.pdf',
  PROJEKT_DO_12 = 'projekt_oferty_na_czas_okreslony_do_12_miesiecy.pdf',
  PROJEKT_POWYZEJ_12 = 'projekt_oferty_na_czas_powyzej_12_miesiecy.pdf',

  CERTYFIKAT_NIEOKRESLONY = 'certyfikat_oferty_na_czas_nieokreslony.pdf',
  CERTYFIKAT_DO_12 = 'certyfikat_oferty_na_czas_okreslony_do_12_miesiecy.pdf',
  CERTYFIKAT_POWYZEJ_12 = 'certyfikat_oferty_na_czas_powyzej_12_miesiecy.pdf',

  CERTYFIKAT_UMOWY_NAJMU = 'certyfikat_umowy_najmu.pdf',

  PROTOCOL = 'protokol_zdawczo-odbiorczy.pdf',
  INVOICE_L = 'faktura-wynajmujacy.pdf',
  INVOICE_T = 'faktura-najemca.pdf',
}

export interface ConfigData {
  title: Titles;
  filePath: FilePaths;
  status: OfferSigningStatus;
}

type Configs = {
  [key in ConfigType]: {
    [subKey: string]: ConfigData;
  };
};

export const configs: Configs = {
  [ConfigType.OFFER]: {
    [OfferSubType.INFINITE]: {
      title: Titles.UMOWA_NIEOKRESLONY,
      filePath: FilePaths.CERTYFIKAT_NIEOKRESLONY,
      status: OfferSigningStatus.OFFER_CERTIFICATE,
    },
    [OfferSubType.FIXED_SHORT]: {
      title: Titles.UMOWA_12_MIESIECY,
      filePath: FilePaths.CERTYFIKAT_DO_12,
      status: OfferSigningStatus.OFFER_CERTIFICATE,
    },
    [OfferSubType.FIXED_LONG]: {
      title: Titles.UMOWA_POWYZEJ_12,
      filePath: FilePaths.CERTYFIKAT_POWYZEJ_12,
      status: OfferSigningStatus.OFFER_CERTIFICATE,
    },
  },
  [ConfigType.LEASE]: {
    [LeaseSubType.ONE]: {
      title: Titles.UMOWA_NIEOKRESLONY,
      filePath: FilePaths.CERTYFIKAT_UMOWY_NAJMU,
      status: OfferSigningStatus.LEASE_OFFER_CERTIFICATE,
    },
    [LeaseSubType.TWO]: {
      title: Titles.UMOWA_12_MIESIECY,
      filePath: FilePaths.CERTYFIKAT_UMOWY_NAJMU,
      status: OfferSigningStatus.LEASE_OFFER_CERTIFICATE,
    },
    [LeaseSubType.THREE]: {
      title: Titles.UMOWA_POWYZEJ_12,
      filePath: FilePaths.CERTYFIKAT_UMOWY_NAJMU,
      status: OfferSigningStatus.LEASE_OFFER_CERTIFICATE,
    },
  },
  [ConfigType.PROJECT]: {
    [OfferSubType.INFINITE]: {
      title: Titles.UMOWA_NIEOKRESLONY,
      filePath: FilePaths.PROJEKT_NIEOKREŚLONY,
      status: OfferSigningStatus.PROJECT,
    },
    [OfferSubType.FIXED_SHORT]: {
      title: Titles.UMOWA_12_MIESIECY,
      filePath: FilePaths.PROJEKT_DO_12,
      status: OfferSigningStatus.PROJECT,
    },
    [OfferSubType.FIXED_LONG]: {
      title: Titles.UMOWA_POWYZEJ_12,
      filePath: FilePaths.PROJEKT_POWYZEJ_12,
      status: OfferSigningStatus.PROJECT,
    },
  },
  [ConfigType.PROTOCOL]: {
    default: {
      title: Titles.PROTOCOL,
      filePath: FilePaths.PROTOCOL,
      status: OfferSigningStatus.PROTOCOL,
    },
  },
  [ConfigType.INVOICE_L]: {
    default: {
      title: Titles.INVOICE,
      filePath: FilePaths.INVOICE_L,
      status: OfferSigningStatus.INVOICE,
    },
  },
  [ConfigType.INVOICE_T]: {
    default: {
      title: Titles.INVOICE,
      filePath: FilePaths.INVOICE_T,
      status: OfferSigningStatus.INVOICE,
    },
  },
};
