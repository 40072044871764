import { Card, Space, Typography } from 'antd';
import React from 'react';

import { CallerProvider } from './CallerContext';
import { CallersStatistics } from './components/ CallersStatistics';
import { CallerApp } from './components/CallerApp';

const { Title, Paragraph } = Typography;

export const CallerHome: React.FC = () => {
  return (
    <CallerProvider>
      <CallerApp>
        <div className="display-grid gap-12">
          <Card>
            <Space direction="vertical" size="large" style={{ width: '100%' }}>
              <Title level={4}>Callers statistics</Title>
              <Paragraph>
                Overview of campaign performance, showing the number of sent offers, link clicks, user sign-ups, and
                follow-up messages.
              </Paragraph>
              <CallersStatistics />
            </Space>
          </Card>
        </div>
      </CallerApp>
    </CallerProvider>
  );
};
