import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { SelectOption } from '../../../../models/main.model';

export function useOfficeTypeOptions(): SelectOption[] {
  const { t } = useTranslation();

  return useMemo(
    () =>
      ['pom. biurowe', 'pom. biurowe z witryną', 'open space', 'sala konferencyjna', 'recepcja'].map((item) => ({
        label: t(`FORMS.OFFER_ADD.OFFICE_TYPE.${item.toUpperCase()}`),
        value: item,
      })),
    [t]
  );
}
