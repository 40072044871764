import React from 'react';

import { App } from './App';
import { PromotionRegulation } from './components/PromotionRegulation';

/**
 * Strona regulaminu promocji "Bezpieczne 100"
 * Ta strona jest renderowana wewnątrz komponentu App, który zapewnia nagłówek i stopkę
 */
export const PromotionRegulationPage: React.FC = () => {
  return (
    <App>
      <div className="container mx-auto py-8" style={{ maxWidth: '1200px' }}>
        <PromotionRegulation />
      </div>
    </App>
  );
};

export default PromotionRegulationPage;
