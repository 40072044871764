import * as React from 'react';
import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import { OfferRelatedWrapper } from '../../components/OfferRelatedWrapper/OfferRelatedWrapper';
import { Typography } from '../../components/Typography/Typography';
import { useGetOffer } from '../../firebase/hooks/getOfferById.hook';
import { useGetOfferImages } from '../../hooks/getOfferImages.hook';
import { App } from '../App/App';
import { useAuth } from '../Auth/AuthContext';
import { AppRoutes } from '../Routing/routing.model';

import { ReceptionOfThePremises } from './components/ReceptionOfThePremises';

interface Props {
  userUid: string;
  offerId: string;
}
export const IndicateTransferPerson: React.FC<Props> = ({ userUid, offerId }) => {
  const { isAuthenticating, isAuth } = useAuth();
  return <App>{!isAuthenticating && isAuth ? <WhenAuth offerId={offerId} userUid={userUid} /> : <WhenNotAuth />}</App>;
};

const WhenAuth: React.FC<Props> = ({ userUid, offerId }) => {
  const { push } = useHistory();
  const isLoading = useRef(false);
  const [offer] = useGetOffer(offerId);
  const imagesUrl = useGetOfferImages(offerId);
  const { t } = useTranslation();
  useEffect(() => {
    if (isLoading.current) return;
    if (!userUid || offerId === undefined) return push(AppRoutes.HOME, { offerId });
    isLoading.current = true;
  }, []);

  return (
    <>
      {offer && (
        <OfferRelatedWrapper
          images={imagesUrl}
          offer={offer}
          description={
            <>
              <Typography.H5>{t('INDICATE_PERSON_HEADING')}</Typography.H5>
              <Typography.Body1>{t('INDICATE_PERSON_DESCRIPTION')}</Typography.Body1>
              <ReceptionOfThePremises id={offerId} landlord={true} offer={offer} />
            </>
          }
          title={t('ALMOST_DONE')}
          loading={!isLoading.current}
          loadingText={t('LOADING')}></OfferRelatedWrapper>
      )}
    </>
  );
};

const WhenNotAuth: React.FC = () => {
  const { showSignInModal, closeModal } = useAuth();

  useEffect(() => {
    showSignInModal();
    return () => closeModal();
  }, []);

  return <></>;
};
