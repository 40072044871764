import { Button, Divider, Input, Modal, Tabs, message } from 'antd';
import React, { useState } from 'react';

import { firebaseFunctions } from '../../../firebase/firebase';
import { FirebaseCallableFunctions } from '../../../firebase/firebase.models';
import { createShortenedLink } from '../services/link.service';

interface LinkModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export const LinkModal: React.FC<LinkModalProps> = ({ isOpen, onClose }) => {
  const [url, setUrl] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [generateLoading, setGenerateLoading] = useState<boolean>(false);
  const [shortenedUrl, setShortenedUrl] = useState<string>('');
  const [generatedUrl, setGeneratedUrl] = useState<string>('');
  const [activeTab, setActiveTab] = useState<string>('1');

  const handleShortenLink = async () => {
    if (!url) {
      message.error('Please enter a URL');
      return;
    }

    // Przygotuj URL z automatycznym dodaniem https:// jeśli brak prefixu
    let processedUrl = url.trim();
    if (!processedUrl.startsWith('http://') && !processedUrl.startsWith('https://')) {
      processedUrl = `https://${processedUrl}`;
    }

    setLoading(true);
    try {
      const newLink = await createShortenedLink(processedUrl);
      const baseUrl = window.location.origin;
      const shortened = `${baseUrl}/l/${newLink.shortId}`;

      setShortenedUrl(shortened);
      message.success('Link shortened successfully!');
    } catch (error) {
      console.error('Error shortening link:', error);
      message.error('Failed to shorten link');
    } finally {
      setLoading(false);
    }
  };

  const handleCopyLink = (linkToCopy: string) => {
    if (linkToCopy) {
      navigator.clipboard.writeText(linkToCopy);
      message.success('Link copied to clipboard!');
    }
  };

  const handleReset = () => {
    if (activeTab === '1') {
      setUrl('');
      setShortenedUrl('');
    } else {
      setEmail('');
      setGeneratedUrl('');
    }
  };

  const handleGenerateUtmLink = async () => {
    if (!email) {
      message.error('Please enter an email address');
      return;
    }

    // Basic email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      message.error('Please enter a valid email address');
      return;
    }

    // Przygotuj opcjonalny customUrl z automatycznym dodaniem https:// jeśli podano URL i brak prefixu
    let customUrl = url ? url.trim() : undefined;
    if (customUrl && !customUrl.startsWith('http://') && !customUrl.startsWith('https://')) {
      customUrl = `https://${customUrl}`;
    }

    setGenerateLoading(true);
    try {
      const resp = await firebaseFunctions.httpsCallable(FirebaseCallableFunctions.GENERATE_UTM_LINK)({
        email: email.trim(),
        customUrl: customUrl, // Już przetworzony URL
      });

      if (resp.data && resp.data.success && resp.data.link) {
        setGeneratedUrl(resp.data.link);
        message.success('UTM link generated successfully!');
      } else {
        throw new Error('Failed to generate UTM link');
      }
    } catch (error) {
      console.error('Error generating UTM link:', error);
      message.error('Failed to generate UTM link');
    } finally {
      setGenerateLoading(false);
    }
  };

  return (
    <Modal
      title="Link Options"
      open={isOpen}
      onCancel={onClose}
      width={600}
      footer={[
        <Button key="cancel" onClick={onClose}>
          Cancel
        </Button>,
      ]}>
      <Tabs
        activeKey={activeTab}
        onChange={(key) => {
          setActiveTab(key);
          setUrl('');
          setEmail('');
          setShortenedUrl('');
          setGeneratedUrl('');
        }}
        items={[
          {
            key: '1',
            label: 'Shorten Link',
            children: (
              <div className="space-y-4">
                <div>
                  <Input
                    placeholder="Enter URL to shorten"
                    value={url}
                    onChange={(e) => setUrl(e.target.value)}
                    disabled={loading || !!shortenedUrl}
                  />
                  <div className="text-xs text-gray-500 mt-1">
                    https:// will be added automatically if not provided
                  </div>
                </div>

                {!shortenedUrl ? (
                  <Button type="primary" block onClick={handleShortenLink} loading={loading}>
                    Shorten Link
                  </Button>
                ) : (
                  <div className="space-y-2">
                    <div className="flex items-center">
                      <Input value={shortenedUrl} readOnly />
                      <Button type="primary" onClick={() => handleCopyLink(shortenedUrl)} className="ml-2">
                        Copy
                      </Button>
                    </div>
                    <Button block onClick={handleReset}>
                      Shorten Another Link
                    </Button>
                  </div>
                )}
              </div>
            ),
          },
          {
            key: '2',
            label: 'Generate UTM Link',
            children: (
              <div className="space-y-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">Email (required)</label>
                  <Input
                    placeholder="Enter recipient email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    disabled={generateLoading || !!generatedUrl}
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">Custom URL (optional)</label>
                  <Input
                    placeholder="Enter custom URL or leave empty for default"
                    value={url}
                    onChange={(e) => setUrl(e.target.value)}
                    disabled={generateLoading || !!generatedUrl}
                  />
                  <div className="text-xs text-gray-500 mt-1">
                    If left empty, the default site URL will be used.
                    https:// will be added automatically if not provided.
                  </div>
                </div>

                {!generatedUrl ? (
                  <Button type="primary" block onClick={handleGenerateUtmLink} loading={generateLoading}>
                    Generate UTM Link
                  </Button>
                ) : (
                  <div className="space-y-2">
                    <div className="flex items-center">
                      <Input value={generatedUrl} readOnly />
                      <Button type="primary" onClick={() => handleCopyLink(generatedUrl)} className="ml-2">
                        Copy
                      </Button>
                    </div>
                    <Button block onClick={handleReset}>
                      Generate Another Link
                    </Button>
                  </div>
                )}
              </div>
            ),
          },
        ]}
      />

      {(shortenedUrl || generatedUrl) && (
        <div className="mt-4">
          <Divider plain>What&apos;s next?</Divider>
          <p className="text-sm text-gray-500">
            Copy this link and share it with your contacts via email, message, or social media.
            {activeTab === '2' && ' This link includes UTM parameters to track user activity.'}
          </p>
        </div>
      )}
    </Modal>
  );
};
