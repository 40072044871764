import { firestore } from '../../../firebase/firebase';
import { FirestoreCollection } from '../../../firebase/firebase.models';
import { Verification } from '../../../models/offers.model';

export const isCompanyConfirmed = async (currentUser: { uid: string }): Promise<Verification> => {
  const doc = await firestore.collection(FirestoreCollection.USERS).doc(currentUser.uid).get();

  const data = doc.data();
  const company = data?.verification?.company as Verification;

  return company || Verification.INITIAL;
};
