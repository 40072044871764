import { Alert, Space, Spin } from 'antd';
import * as React from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import { CompanyModal } from '../../components/Alerts/CompanyModal';
import { IdentityModal } from '../../components/Alerts/IdentityModal';
import { ToastVariant } from '../../components/Toast/toast.model';
import { toastService } from '../../components/Toast/toast.service';
import { firebaseFunctions } from '../../firebase/firebase';
import { FirebaseCallableFunctions } from '../../firebase/firebase.models';
import { useGetOffer } from '../../firebase/hooks/getOfferById.hook';
import { useGetProjectOffer } from '../../firebase/hooks/getProjectOfferById.hook';
import { Offer, OfferStatusState } from '../../models/offers.model';
import { App, SHOW_LOGS } from '../App/App';
import { handleCampaign, useAuth } from '../Auth/AuthContext';
import { AppRoutes } from '../Routing/routing.model';

import { HowItWorks } from './components/HowItWorks';
import { OfferForm } from './components/OfferForm';
import { handleOnOfferAdd } from './hooks/handleOnOfferAdd.hook';
import useSessionTracker from './hooks/useSessionTracker.hook';
import { ImageVerificationModal } from './ImageVerificationModal';

interface Props {
  offerId: string;
}

interface PropsWhenAuth {
  offerId: string;
  initial: Offer;
  error: string;
  currentUser: any;
  hasPermission: boolean;
}

export const OfferAdd: React.FC<Props> = ({ offerId }) => {
  const { currentUser } = useAuth();
  const { offer, error, hasPermission } = useGetProjectOffer(offerId);
  useSessionTracker(offer?.id);

  const callLinkProjectOffer = async () => {
    try {
      const campaignData = handleCampaign();
      if (!campaignData) return;

      const callableFunction = firebaseFunctions.httpsCallable(FirebaseCallableFunctions.ON_CLICK_OFFER_ADD_LINK);
      const resp = await callableFunction(campaignData);

      SHOW_LOGS && console.log(resp);
    } catch (error) {
      console.error('Error in callLinkProjectOffer:', error);
    }
  };

  React.useEffect(() => {
    callLinkProjectOffer();
  }, []);

  return (
    <App>
      <WhenAuth
        offerId={offerId}
        initial={offer}
        error={error}
        currentUser={currentUser}
        hasPermission={hasPermission}
      />
    </App>
  );
};

const WhenAuth: React.FC<PropsWhenAuth> = ({ offerId, initial, error, currentUser, hasPermission }) => {
  const { push } = useHistory();
  const [loading, setLoading] = useState<boolean>();
  const [isIdentityModalOpen, setIsIdentityModalOpen] = useState(false);
  const [isCompanyModalOpen, setIsCompanyModalOpen] = useState(false);
  const [offer] = useGetOffer(offerId);
  const [imagesVerificationResults, setImagesVerificationResults] = useState<string | null>(null);
  const [showVerificationModal, setShowVerificationModal] = useState(false);
  const { t } = useTranslation();
  const [hasSubmitted, setHasSubmitted] = useState(false);

  console.log('DEBUG: offer', offer);
  console.log('DEBUG: initial', initial);

  const onOfferAdd = async () => {
    if (hasSubmitted) return;
    setHasSubmitted(true);
    const { success, description } = await handleOnOfferAdd(
      currentUser,
      offer,
      setIsIdentityModalOpen,
      setIsCompanyModalOpen,
      setLoading,
      setImagesVerificationResults,
      push
    );

    toastService.show(description, success ? t('SUCCESS') : t('ERROR_DESCRIPTION'), {
      variant: success ? ToastVariant.SUCCESS : ToastVariant.ERROR,
    });

    if (imagesVerificationResults) {
      setShowVerificationModal(true);
    }
  };

  const handleCloseModal = () => setShowVerificationModal(false);

  if (error) {
    return (
      <>
        <Space direction="vertical" style={{ width: '100%', marginTop: '100px', marginBottom: '60%', padding: '50px' }}>
          <Alert showIcon message={t('NO_ACCESS_TO_FORM')} description={error} type="error" />
        </Space>
      </>
    );
  } else if (initial === undefined || !offer) {
    return (
      <Space size="middle" className="absolute top-1/2 right-1/2">
        <Spin />
      </Space>
    );
  } else if (offer?.offerStatus?.state !== OfferStatusState.UNSIGNED) {
    return <WhenOfferSigned />;
  } else {
    return (
      <>
        <OfferForm
          offerId={offerId}
          initial={initial}
          onAddOffers={onOfferAdd}
          isAddingPending={loading}
          hasPermission={hasPermission}
        />
        <IdentityModal isIdentityModalOpen={isIdentityModalOpen} />
        <CompanyModal isCompanyModalOpen={isCompanyModalOpen} />
        <ImageVerificationModal
          show={showVerificationModal}
          onClose={handleCloseModal}
          verificationResults={imagesVerificationResults}
          onChangeImage={() => push(AppRoutes.ADD_OFFER.replace(':id', offer.id))}
        />
        <HowItWorks />
      </>
    );
  }
};

const WhenOfferSigned: React.FC = () => {
  const { t } = useTranslation();
  return (
    <>
      <Space direction="vertical" style={{ width: '100%', marginTop: '100px', marginBottom: '60%', padding: '50px' }}>
        <Alert showIcon message={t('ERROR')} description={t('OFFER_NOT_EDITABLE')} type="error" />
      </Space>
    </>
  );
};
