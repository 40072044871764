import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { SelectOption } from '../../../models/main.model';

export function useEquipmentConditionOptions(): SelectOption[] {
  const { t } = useTranslation(); // Dodajemy hook tłumaczeń

  return useMemo(
    () =>
      ['Nowe', 'Używany - Dobry stan', 'Używany - Umiarkowany stan', 'Odnowiony', 'Nie dotyczy'].map((item) => ({
        label: t(`PROTOCOL.EQUIPMENT_CONDITION.${item.toUpperCase()}`),
        value: item,
      })),
    [t]
  );
}
