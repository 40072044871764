import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { SelectOption } from '../../../../models/main.model';

export function useNonRentFees(): SelectOption[] {
  const { t } = useTranslation();

  return useMemo(
    () => [
      { label: t('DETAILS.FINANCIAL.METERED'), value: 'Licznikowe' },
      { label: t('DETAILS.FINANCIAL.IN_RENT'), value: 'W czynszu najmu' },
      { label: t('DETAILS.FINANCIAL.LUMP_SUM'), value: 'Ryczałtowe' },
    ],
    [t]
  );
}
