import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next'; // Importing the translation hook

import { firestore } from '../../../firebase/firebase';
import { FirestoreCollection, OfferOption } from '../../../firebase/firebase.models';
import { LeaseAgreement } from '../../../models/offers.model';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function useGetLeasements(offerId: string, leaseAgreement?: LeaseAgreement) {
  const { t } = useTranslation(); // Using the translation hook
  const [leaseAgreements, setLeaseAgreements] = useState<OfferOption[]>([]);

  useEffect(() => {
    if (!leaseAgreement) {
      console.warn('Lease agreement is undefined or null.');
      return;
    }

    firestore
      .collection(FirestoreCollection.OFFERS_PUBLIC)
      .doc(offerId)
      .get()
      .then((doc) => {
        if (doc.exists) {
          const leaseData = doc.data()?.leaseAgreement;
          const offers: OfferOption[] = [];

          if (leaseData.infinite) {
            offers.push({
              id: 1,
              title: t('LEASE_OPTIONS.INFINITE'), // Translated title
              qualifiedSignature: false,
            });
          }
          if (leaseData.fixedshort) {
            offers.push({
              id: 2,
              title: `${t('LEASE_OPTIONS.SHORT_TERM').replace('12', leaseData.fixedshortMonths)}`, // Translated title
              qualifiedSignature: false,
            });
          }
          if (leaseData.fixedlong) {
            offers.push({
              id: 3,
              title: `${t('LEASE_OPTIONS.LONG_TERM').replace('12', leaseData.fixedlongMonths)}`, // Translated title
              qualifiedSignature: true,
            });
          }

          setLeaseAgreements(offers);
        } else {
          console.error('No such document!');
        }
      })
      .catch((error) => {
        console.error('Error getting document:', error);
      });
  }, [offerId, leaseAgreement, t]); // Adding t to the dependency array

  return leaseAgreements;
}
