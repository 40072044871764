import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { firebaseStorage } from '../../../firebase/firebase';
import { useDocumentModal } from '../../Documents/DocumentModalProvider';

interface Props {
  i18nKey: string;
  linkPaths: { [key: string]: string };
}

export const LocalizedLabelWithMultipleModals: React.FC<Props> = ({ i18nKey, linkPaths }) => {
  const { t } = useTranslation();
  const { showDocumentModal } = useDocumentModal();
  const [resolvedLinks, setResolvedLinks] = useState<{ [key: string]: string }>({});
  const isMounted = useRef(true);

  useEffect(() => {
    // Funkcja do uzyskania pełnego URL z Firebase Storage
    const resolveLinks = async () => {
      const resolved: { [key: string]: string } = {};
      for (const key in linkPaths) {
        if (linkPaths[key].startsWith('documents')) {
          try {
            const fileRef = firebaseStorage.ref(linkPaths[key]);
            const url = await fileRef.getDownloadURL();
            if (isMounted.current) {
              resolved[key] = url;
            }
          } catch (error) {
            console.error('Error fetching document URL:', error);
            if (isMounted.current) {
              resolved[key] = '#';
            }
          }
        } else {
          resolved[key] = linkPaths[key];
        }
      }
      if (isMounted.current) {
        setResolvedLinks(resolved);
      }
    };

    resolveLinks();
  }, [linkPaths]);

  const rawText = t(i18nKey);
  const components = [];

  let cursor = 0;
  rawText.replace(/<(\d+)>/g, (match, key, offset) => {
    // Tekst przed znacznikiem
    components.push(rawText.slice(cursor, offset));

    // Etykieta linku
    const linkLabel = rawText.slice(offset + match.length, rawText.indexOf(`</${key}>`, offset));
    components.push(
      <a
        key={key}
        onClick={() => showDocumentModal(resolvedLinks[key])}
        className="text-blue-600 cursor-pointer"
        role="button">
        {linkLabel}
      </a>
    );

    // Aktualizacja kursora
    cursor = rawText.indexOf(`</${key}>`, offset) + `</${key}>`.length;

    return match;
  });

  // Dodanie pozostałego tekstu
  components.push(rawText.slice(cursor));

  return <>{components}</>;
};
