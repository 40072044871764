import { AppRoutes } from '../../Routing/routing.model';

export function additionalNavigation(key: string): void {
  if (key == '1') {
    window.open(
      `https://${process.env.REACT_APP_DOMAIN}/${AppRoutes.DOCUMENT.replace(
        ':documentPath',
        'documents&FL_AspektyPrawne.pdf'
      )}`,
      '_blank'
    );
  }
  if (key == '2') {
    window.open(
      `https://${process.env.REACT_APP_DOMAIN}/${AppRoutes.DOCUMENT.replace(
        ':documentPath',
        'documents&FL_Regulamin.pdf'
      )}`,
      '_blank'
    );
  }
  if (key == '3') {
    window.open(
      `https://${process.env.REACT_APP_DOMAIN}/${AppRoutes.DOCUMENT.replace(
        ':documentPath',
        'documents&FL_Regulamin-Wynajmujacy.pdf'
      )}`,
      '_blank'
    );
  }
  if (key == '4') {
    window.open(
      `https://${process.env.REACT_APP_DOMAIN}/${AppRoutes.DOCUMENT.replace(
        ':documentPath',
        'documents&FL_Regulamin-Najemca.pdf'
      )}`,
      '_blank'
    );
  }
  if (key == '5') {
    window.open(
      `https://${process.env.REACT_APP_DOMAIN}/${AppRoutes.DOCUMENT.replace(
        ':documentPath',
        'documents&FL_Cennik.pdf'
      )}`,
      '_blank'
    );
  } else {
    return;
  }
}
