import { Alert, InputNumber, Switch, message } from 'antd';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Modal } from 'rsuite';
import styled from 'styled-components';

import { PanelContent, PanelWhite } from '../../../components/Layout/styles';
import { Typography } from '../../../components/Typography/Typography';
import { firebaseFunctions, firestore } from '../../../firebase/firebase';
import { FirebaseCallableFunctions, FirestoreCollection } from '../../../firebase/firebase.models';
import { Verification } from '../../../models/offers.model';
import { useAuth } from '../../Auth/AuthContext';
import { AppRoutes } from '../../Routing/routing.model';

const StyledButton = styled(Button)`
  margin-top: 16px;
  background-color: grey;
  &:hover {
    background-color: black !important;
  }
`;

interface Props {
  tenant: string;
  offerId: string;
  price: number;
  landlord: string;
}

export const CounterOffer: React.FC<Props> = ({ tenant, offerId, price, landlord }) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [newPrice, setNewPrice] = useState('');
  const [newDuration, setNewDuration] = useState('');
  const [isRentToggleChecked, setIsRentToggleChecked] = useState(false);
  const [isContractDurationToggleChecked, setIsContractDurationToggleChecked] = useState(false);

  const [isUserVerified, setIsUserVerified] = useState(true);
  const { currentUser } = useAuth();
  const { push } = useHistory();

  useEffect(() => {
    firestore
      .collection(FirestoreCollection.USERS)
      .doc(currentUser.uid)
      .get()
      .then((doc) => {
        if (
          !currentUser.emailVerified ||
          doc.data().verification.identity !== Verification.ACCEPTED ||
          doc.data().verification.company !== Verification.ACCEPTED
        ) {
          setIsUserVerified(false);
        }
      });
  }, []);

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const handlePriceChange = (value: string) => {
    setNewPrice(value);
  };

  const handleDurationChange = (value: string) => {
    setNewDuration(value);
  };

  const handleSubmit = () => {
    if (!isUserVerified) {
      message.error('Twoje konto nie jest zweryfikowane. Nie możesz wysłać kontroferty.');
      closeModal();
      return;
    }

    if (!isRentToggleChecked && !isContractDurationToggleChecked) {
      closeModal();
      return;
    }
    closeModal();
    const hide = message.loading('Wysyłanie kontroferty...', 0);

    // funkcja cloudowa która wysle maila do wynajmującego
    const onCounterOffer = firebaseFunctions.httpsCallable(FirebaseCallableFunctions.ON_COUNTER_OFFER);
    onCounterOffer({
      isRentToggleChecked,
      isContractDurationToggleChecked,
      newPrice,
      newDuration,
      landlord,
      tenant,
      offerId,
    })
      .then(() => {
        hide();
        message.success('Kontroferta została wysłana.');
      })
      .catch((error) => {
        hide();
        message.error('Błąd podczas wysyłania kontrofert.');
        console.error(error);
      });
  };

  return (
    <PanelWhite bodyFill>
      <PanelContent>
        <Typography.H3>Warunki oferty nie do końca pasują?</Typography.H3>
        <Typography.H6>Możesz zaproponować inną cenę lub czas trwania umowy</Typography.H6>
        <StyledButton appearance="primary" size={'lg'} block onClick={openModal}>
          Zaproponuj zmianę
        </StyledButton>
      </PanelContent>
      <Modal show={isModalOpen} onHide={closeModal}>
        <Modal.Header>
          <Modal.Title>{isUserVerified ? 'Zaproponuj nowe warunki' : 'Potwierdź swoją tożsamość'}</Modal.Title>
        </Modal.Header>
        {isUserVerified ? (
          <Modal.Body className="flex justify-between">
            <div>
              <div className="my-4">
                <span className="pr-2">Wysokość czynszu najmu</span>
                <Switch checked={isRentToggleChecked} onChange={(checked) => setIsRentToggleChecked(checked)} />
              </div>
              <span>Zaproponuj inną cenę niż {price} PLN</span>
              <InputNumber
                min="1"
                placeholder="Nowa cena"
                value={newPrice}
                onChange={handlePriceChange}
                className="w-56"
                addonAfter="PLN"
                disabled={!isRentToggleChecked}
                style={{ paddingTop: 8 }}
              />
            </div>
            <div>
              <div className="my-4">
                <span className="pr-2">Długość umowy najmu</span>
                <Switch
                  checked={isContractDurationToggleChecked}
                  onChange={(checked) => setIsContractDurationToggleChecked(checked)}
                />
              </div>
              <span>Na jaki czas chcesz zawrzeć umowę?</span>
              <InputNumber
                min="1"
                placeholder="Nowy czas trwania umowy"
                value={newDuration}
                onChange={handleDurationChange}
                className="w-56"
                addonAfter="mies."
                disabled={!isContractDurationToggleChecked}
                precision={0}
                style={{ paddingTop: 8 }}
              />
            </div>
          </Modal.Body>
        ) : (
          <Modal.Body>
            <Alert
              message="Potwierdź swoją tożsamość"
              description="Nie możesz podpisać umowy gdy nie masz potwierdzonej tożsamości"
              type="error"
              showIcon
              action={
                <Button
                  type="primary"
                  style={{ backgroundColor: 'red', color: 'white' }}
                  onClick={() => {
                    push(AppRoutes.MOJA_TABLICA_CONFIRM_IDENTITY);
                  }}>
                  Potwierdź
                </Button>
              }
            />
          </Modal.Body>
        )}

        <Modal.Footer>
          <Button onClick={closeModal} className={!isUserVerified ? 'hidden' : ''}>
            Anuluj
          </Button>
          <Button
            appearance="primary"
            onClick={handleSubmit}
            disabled={!isUserVerified}
            className={!isUserVerified ? 'hidden' : ''}>
            Zatwierdź
          </Button>
        </Modal.Footer>
      </Modal>
    </PanelWhite>
  );
};
