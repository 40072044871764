import React, { ReactNode, useEffect, useState } from 'react';
import { Container, Content, Footer } from 'rsuite';

import { firebaseFunctions } from '../../firebase/firebase';
import { FirebaseCallableFunctions } from '../../firebase/firebase.models';
import { handleCampaign } from '../Auth/AuthContext';

import { FixedMenu } from './components/FixedMenu';
import { FooterContent } from './components/Footer';
import { InfoBeta } from './components/InfoBeta';
import { SpecialOffer100 } from './components/SpecialOffer100';

export const SHOW_LOGS = process.env.NODE_ENV !== 'production';

interface Props {
  pageHeading?: ReactNode;
}
export const App: React.FC<Props> = ({ children, pageHeading }) => {
  // Flaga, która zapobiega wielokrotnemu śledzeniu kliknięć w ramach jednej sesji
  const [campaignTracked, setCampaignTracked] = useState<boolean>(false);

  // Śledź parametry UTM przy ładowaniu aplikacji
  useEffect(() => {
    const trackCampaignClick = async () => {
      // Zapobiegaj wielokrotnemu śledzeniu w tej samej sesji
      if (campaignTracked) return;

      try {
        const campaignData = handleCampaign();
        if (!campaignData) return;

        // Jeśli mamy dane kampanii, wywołaj funkcję do śledzenia kliknięć
        const callableFunction = firebaseFunctions.httpsCallable(FirebaseCallableFunctions.ON_CLICK_OFFER_ADD_LINK);
        await callableFunction(campaignData);

        // Ustaw flagę, aby zapobiec wielokrotnemu śledzeniu
        setCampaignTracked(true);

        SHOW_LOGS && console.log('Campaign click tracked:', campaignData);
      } catch (error) {
        console.error('Error tracking campaign click:', error);
      }
    };

    trackCampaignClick();
  }, [campaignTracked]);

  return (
    <Container>
      <InfoBeta />
      <div>
        <FixedMenu />
        {pageHeading}
      </div>
      <SpecialOffer100 />
      <Content>{children}</Content>
      <Footer>
        <FooterContent />
      </Footer>
    </Container>
  );
};
