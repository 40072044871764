import { useMemo } from 'react';

import { SelectOption } from '../../../../models/main.model';

export function useEnergyCategory(): SelectOption[] {
  return useMemo(
    () =>
      ['A+', 'A', 'B', 'C', 'D', 'E', 'F', 'G'].map((item) => ({
        label: item,
        value: item,
      })),
    []
  );
}
