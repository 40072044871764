export function copyTextToClipboard(text: string, onSuccess?: () => void, onError?: () => void): void {
  if (!navigator.clipboard) {
    console.error('Clipboard API not supported');
    if (onError) onError();
    return;
  }

  navigator.clipboard
    .writeText(text)
    .then(() => {
      if (onSuccess) onSuccess();
    })
    .catch((error) => {
      console.error('Failed to write to clipboard:', error);
      if (onError) onError();
    });
}

// Stała eksportowana z AuthContext.tsx - musimy utrzymać je zsynchronizowane
export const UTM_STORAGE_KEY = 'najmuje_utm_params';

interface NavigateLinkOptions {
  preserveUtm?: boolean;
}

/**
 * Funkcja pomocnicza do tworzenia linków z zachowaniem parametrów UTM
 * Jeśli opcja preserveUtm jest true, próbuje dodać parametry UTM z localStorage
 */
export const navigateLink = (path: string, options: NavigateLinkOptions = { preserveUtm: true }): string => {
  if (!options.preserveUtm) return path;

  try {
    // Pobierz zapisane parametry UTM
    const storedData = localStorage.getItem(UTM_STORAGE_KEY);
    if (!storedData) return path;

    const { params, expiry } = JSON.parse(storedData);

    // Sprawdź czy dane nie wygasły
    if (new Date().getTime() > expiry) {
      localStorage.removeItem(UTM_STORAGE_KEY);
      return path;
    }

    // Dodaj parametry UTM do URL
    const url = new URL(path, window.location.origin);

    // Dodaj tylko jeśli path nie zawiera już parametrów UTM
    if (
      !url.searchParams.has('utm_source') &&
      !url.searchParams.has('utm_medium') &&
      !url.searchParams.has('utm_campaign')
    ) {
      url.searchParams.set('utm_source', params.utmSource);
      url.searchParams.set('utm_medium', params.utmMedium);
      url.searchParams.set('utm_campaign', params.utmCampaign);
      url.searchParams.set('utm_id', params.utmId);
      url.searchParams.set('utm_term', params.utmTerm);
      url.searchParams.set('utm_content', params.utmContent);
    }

    // Zwróć nowy URL z dodanymi parametrami
    return url.pathname + url.search;
  } catch (error) {
    console.error('Error adding UTM params to link:', error);
    return path;
  }
};
