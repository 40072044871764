import { Company } from '../features/MojaTablica/Account/Identity/LinkCompany/company.models';

import { SelectOption } from './main.model';
import { ProtocolData } from './protocol.model';

export enum PremisesPurpose {
  BIUROWE = 'Biurowe',
  'HANDLOWO-USŁUGOWE' = 'Handlowo-Usługowe',
  'MAGAZYNOWO-PRZEMYSŁOWE' = 'Magazynowo-Przemysłowe',
}
export enum OfferType {
  RENT = 'rent',
  SELL = 'sell',
}
export enum OfferStatusState {
  NEW = 'NEW',
  UNSIGNED = 'UNSIGNED',
  ACTIVE = 'ACTIVE',
  BOOKED = 'BOOKED',
  DEPOSIT_PENDING = 'DEPOSIT_PENDING',
  SIGNED = 'SIGNED',
  DELETED = 'DELETED',
}
export enum PostSigningStatus {
  NONE = 'NONE', // brak potrzeby wpłacania depozytu - omiń proces kaucji
  DEPOSIT_PENDING = 'DEPOSIT_PENDING', // czekanie na przelew
  DEPOSIT_CONFIRMED = 'DEPOSIT_CONFIRMED', // przelew potwierdzony przez moderatora
  REJECTED = 'REJECTED', // odrzucone przez admina (proces się zmienił i nie wiem czy to jest nawet potrzebne)
  AFTER_REJECTION = 'AFTER_REJECTION',

  PROTOCOL_ATTACHED = 'PROTOCOL_ATTACHED',
  PROTOCOL_REJECTED = 'PROTOCOL_REJECTED',
  PROTOCOL_CONFIRMED = 'PROTOCOL_CONFIRMED',
}

export interface OfferLatLng {
  lat: number;
  lng: number;
}
export interface OfferStatus {
  state: OfferStatusState;
  tenant?: {
    email: string;
    uid: string;
    companyId: string;
  };
  landlord?: {
    companyId: string;
    email: string;
    uid: string;
  };
  postSigningStatus?: PostSigningStatus;
}

export const distanceOptions: SelectOption[] = [
  { label: '1 km', value: 'km1', role: 'Master' },
  { label: '5 km', value: 'km5', role: 'Master' },
  { label: '10 km', value: 'km10', role: 'Master' },
  { label: '25 km', value: 'km25', role: 'Master' },
  { label: '50 km', value: 'km50', role: 'Master' },
  { label: '100 km', value: 'km100', role: 'Master' },
  { label: '200 km', value: 'km200', role: 'Master' },
];

// export const offerDetailsMap: Record<keyof OfferDetails, string> = {
export const offerDetailsMap = {
  drive: 'DRIVE',
  water: 'WATER',
  surroundings: 'SURROUNDINGS',
  heating: 'HEATING',
  telephoneLines: 'TELEPHONE_LINES',
  internet: 'INTERNET',
  communicationDistance: 'COMMUNICATION_DISTANCE',
  airConditioning: 'AIR_CONDITIONING',
  reception: 'RECEPTION',
  lan: 'LAN',
  wheelchairAccessible: 'WHEELCHAIR_ACCESSIBLE',
  parking: 'PARKING',
  area: 'AREA',
};

/**
 *
 */

export enum LegalWarehouseUseAccepted {
  STORAGE = 'Magazynowanie',
  PRODUCTION = 'Produkcja',
  WHOLESALE = 'Sprzedaż Hurtowa',
  RETAIL_SALE = 'Sprzedaż detaliczna',
  OFFICE = 'Biuro',
  AFTERSALES = 'Serwis',
  SERVICE = 'Usługi',
}
export interface OfferDetailsRelatedOffice {
  raisedFloors: boolean;
  raisedCeilings: boolean;
  alarm: boolean;
  monitoring: boolean;
  openingWindows: boolean;
  officeEquipment: string[];
}
export interface OfferDetailsRelatedCommercialAndService {
  anyArrangement: boolean;
  independentVentilationChannel: boolean;
  numberOfEntries: number;
  columnsGridStructure?: {
    x: number;
    y: number;
  };
  commercialAndServiceEquipment?: string[];
}
export interface OfferDetailsRelatedWarehouse {
  windowOpenings: boolean;
  dustFreeFloor: boolean;
  TIRHallEntrance: boolean;
  crane: boolean;
  mechanicalVentilation: boolean;
  highBayRacking: boolean;
  railRamp: boolean;
  naturalVentilation: boolean;

  warehouseEquipment: string[];
  warehouseAirConditioning: boolean;
  warehouseGroundLevel: boolean;
  carRamp: boolean;
  warehouseManagement: boolean;
  forklift: boolean;
  gates?: {
    width: number;
    height: number;
  }[];
  hallHeight: number;
  // lift & liftCapacity zostały przeniesione z Warehouse do Levels jednak zostają w modelu ze względu na
  // problemy jakie pojawiłyby się z wyświetlaniem starych ofert sprzed tej zmiany
  lift?: boolean;
  liftCapacityKg?: number;
  columnsGridStructure?: {
    x: number;
    y: number;
  };
}
export interface OfferLevelDetails {
  levelNumber: number;
  levelName: string;
  surface: number;
  height: number;
  numberOfStaircases: number;
  accessibility: boolean;
  toiletsLocation: boolean;
  offices?: OfferOfficeDetails[];
  warehouses?: OfferWarehouseDetails[];
  commercialAndServiceRooms?: OfferCommercialAndServiceRoomDetails[];
  technicalRooms?: OfferTechnicalRoomDetails[];
  otherRooms: boolean[];
  otherRoomsDetails: string[];
  lift: boolean;
  liftCapacityKg: number;
}
export interface OfferOfficeDetails {
  type: string;
  surface: number;
  windows: boolean;
}
export interface OfferWarehouseDetails {
  type: string;
  surface: number;
  windows: boolean;
}
export interface OfferTechnicalRoomDetails {
  type: string;
  surface: number;
  windows: boolean;
}
export interface OfferCommercialAndServiceRoomDetails {
  type: string;
  surface: number;
  windows: boolean;
}
export interface OfferAreaDetails {
  surface: number;
  totalSurface: number;
  levels?: OfferLevelDetails[];
  surroundings: string[];
  publicTransportDistance: number;
  drive: string[];
  buildingType: string;
  constructionMaterial: string[];
  thermalInsulation: string[];
  constructionCompletionYear: number;
  landArea: number;
}
interface OfferParkingDetails {
  parkingSurface: number;
  totalParkingSurface: number;
  guardedParking: boolean;
  parkingLotsAvailable: number;
}
interface OfferMediaDetails {
  powerAllocation: number;
  energyCategory: string;
  airConditioning: boolean;
  gravityVentilation: boolean;
  heatRecovery: boolean;
  transformerIn: boolean;
  doublePowerLines: boolean;
  water: boolean;
  gas: boolean;
  sewerage: boolean;
  heating: boolean;
  fiber: boolean;
  LAN: boolean;
  phoneLine: boolean;
}
export interface OfferAddressDetails {
  street: string;
  postCode: string;
  country: string;
  province: string;
  district: string;
  city: string;
  plotNumber: string;
  ownershipType: string;
  inputText: string;
  officialAddress: OfficialAddress;
}
export interface OfficialAddress {
  houseNumber: string;
  street: string;
  estate: string;
  district: string;
  county: string;
  province: string;
}
export interface LeaseAgreement {
  infinite: boolean;
  fixedshort: boolean;
  fixedlong: boolean;
  leaseNoticePeriod?: number;
  fixedshortMonths?: number;
  fixedlongMonths?: number;
  deposit: number;
  payDay: number;
  nonRentFees: string;
  lumpSum?: number;
  averageMonthlyCostsAnnualized?: number;
  averageMonthlyCostsAnnualizedKnown?: boolean;
  selectedLeaseId?: number;
  firstPaymentDay: number;
}
interface Summary {
  summary1: boolean;
  summary2: boolean;
  summary3: boolean;
  summary4: boolean;
}
interface OwnTerms {
  selected: boolean;
  terms: string;
}
export interface ReceptionOfThePremise {
  email: string;
  firstName: string;
  lastName: string;
}
export interface TransferOfThePremise {
  email: string;
  firstName: string;
  lastName: string;
}
export interface ReceptionTime {
  date: string;
  hour: string;
}

export interface Offer {
  offerType: OfferType.RENT;
  description: string;
  legalMainUse: string;
  legalWarehouseUseAccepted: string[];
  legalWarehouseUseOfficial: string[];
  areaDetails: OfferAreaDetails;
  parkingDetails: OfferParkingDetails;
  mediaDetails: OfferMediaDetails;
  relatedDetails: Partial<
    OfferDetailsRelatedWarehouse | OfferDetailsRelatedOffice | OfferDetailsRelatedCommercialAndService
  >;
  landlord: string;
  id: string;
  address: OfferAddressDetails;
  name: string;
  price: number;
  priceCurrency: string;
  geoMarker: OfferLatLng;
  offerStatus: OfferStatus;
  availability: Date;
  availabilityTo: Date | null;
  offerValidTo: Date;
  leaseAgreement: LeaseAgreement;
  summary: Summary;
  uid: string;
  unitReceiptDeadline: number;
  ownTerms: OwnTerms;
  receptionOfThePremise?: ReceptionOfThePremise;
  transferOfThePremise?: TransferOfThePremise;
  receptionTime?: ReceptionTime;
  counteroffer: CounterOffer;
  protocolData?: ProtocolData;
  bookedBy?: BookedBy[];
  subleaseAgreement?: boolean; // tylko do walidacji
  placeId?: string;
  landAndMortgageRegisterNumber: string;
  isInterestedInInsurance?: boolean;
}

interface BookedBy {
  email: string;
  uid: string;
}

export interface CounterOffer {
  selected: boolean;
  isRentToggleChecked: boolean;
  isContractDurationToggleChecked: boolean;
  newPrice: number;
  newDuration: number;
  tenant: string;
}

export enum Verification {
  INITIAL = 'INITIAL',
  REJECTED = 'REJECTED',
  ACCEPTED = 'ACCEPTED',
  CHECKING = 'CHECKING',
}

export interface ReceptionForm {
  firstName: string;
  lastName: string;
  email: string;
  date: Date | string;
  hour: Date | string;
  authorized: boolean;
}

export interface SearchFormFilters {
  premisesPurpose?: PremisesPurpose;
  freeFrom?: Date;
  minArea?: string;
  maxPrice?: string;
  street?: string;
  estate?: string;
  district?: string;
  county?: string;
  province?: string;
}

export interface landlordInfo {
  firstName: string;
  lastName: string;
  company: Company;
}
