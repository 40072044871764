import type { PaginationProps } from 'antd';
import { Card, Col, Input, Row, Select, Spin, Table } from 'antd';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import isoWeek from 'dayjs/plugin/isoWeek';
import utc from 'dayjs/plugin/utc'; // Import pluginu UTC
import React, { useState } from 'react';
import { CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

import { CallerData } from '../../Admin/models/statistics.model';
import { useGetCallersStatistics } from '../hooks/useGetCallersStatistics.hook';

dayjs.extend(customParseFormat);
dayjs.extend(isoWeek);
dayjs.extend(utc); // Rozszerzenie o utc

const { Option } = Select;

/**
 * Funkcja pomocnicza – sprawdza, czy data mieści się w przedziale [start, end]
 */
const isBetweenInclusive = (date: dayjs.Dayjs, start: dayjs.Dayjs, end: dayjs.Dayjs): boolean => {
  return (date.isAfter(start) || date.isSame(start)) && (date.isBefore(end) || date.isSame(end));
};

/**
 * Oblicza statystyki dla każdego dzwoniącego.
 * Zakładamy, że każdy user (dokument) reprezentuje jedną ofertę lub link do strony.
 * Wartości są sumowane dla całego callera (wszystkie eventy z newUsers i existingUsers).
 * Dla wysłań (sends) liczymy: 1 (podstawowa wysyłka) + (entry.sentAgain || 0).
 * Używamy dayjs.utc(...).local(), aby timestampy zapisane w UTC poprawnie przeliczyć na czas lokalny.
 */
const calculateStatistics = (callers: CallerData[], startDate: dayjs.Dayjs, endDate: dayjs.Dayjs) => {
  return callers.map((caller) => {
    let totalOffersSent = 0;
    let totalWebsiteLinksSent = 0; // Nowe pole - wysłane linki do strony
    let totalSends = 0;
    let totalLinksOpened = 0;
    let totalUserSignups = 0;
    let totalIdentityVerifications = 0;
    let totalCompanyVerifications = 0;
    let totalOfferSubmissions = 0;
    let totalContractSignings = 0;
    let bonusSum = 0;

    const allEntries = { ...caller.newUsers, ...caller.existingUsers };

    // Najpierw obliczamy oferty, linki do strony i wysłania na podstawie daty kampanii
    Object.values(allEntries).forEach((entry) => {
      const campaignTimestamp = dayjs.utc(entry.campaignData.timestamp).local();
      if (isBetweenInclusive(campaignTimestamp, startDate, endDate)) {
        // Sprawdzamy typ kampanii, aby rozróżnić oferty od linków do strony
        if (entry.campaignData.utmSource === 'website' || entry.campaignData.utmCampaign === 'direct_call_website') {
          totalWebsiteLinksSent += 1;
        } else {
          totalOffersSent += 1;
        }
        totalSends += 1 + (entry.sentAgain || 0);
      }
    });

    // Następnie sumujemy bonusy i liczymy eventy według ich własnych timestampów
    Object.values(allEntries).forEach((entry) => {
      if (entry.linkClick && entry.linkClick.value) {
        const eventDate = dayjs.utc(entry.linkClick.timestamp).local();
        if (isBetweenInclusive(eventDate, startDate, endDate)) {
          totalLinksOpened += 1;
          bonusSum += entry.linkClick.bonus;
        }
      }
      if (entry.accountCreation && entry.accountCreation.value) {
        const eventDate = dayjs.utc(entry.accountCreation.timestamp).local();
        if (isBetweenInclusive(eventDate, startDate, endDate)) {
          totalUserSignups += 1;
          bonusSum += entry.accountCreation.bonus;
        }
      }
      if (entry.identityVerification && entry.identityVerification.value) {
        const eventDate = dayjs.utc(entry.identityVerification.timestamp).local();
        if (isBetweenInclusive(eventDate, startDate, endDate)) {
          totalIdentityVerifications += 1;
          bonusSum += entry.identityVerification.bonus;
        }
      }
      if (entry.companyVerification && entry.companyVerification.value) {
        const eventDate = dayjs.utc(entry.companyVerification.timestamp).local();
        if (isBetweenInclusive(eventDate, startDate, endDate)) {
          totalCompanyVerifications += 1;
          bonusSum += entry.companyVerification.bonus;
        }
      }
      if (entry.offerSubmission && entry.offerSubmission.value) {
        const eventDate = dayjs.utc(entry.offerSubmission.timestamp).local();
        if (isBetweenInclusive(eventDate, startDate, endDate)) {
          totalOfferSubmissions += 1;
          bonusSum += entry.offerSubmission.bonus;
        }
      }
      if (entry.contractSigning && entry.contractSigning.value) {
        const eventDate = dayjs.utc(entry.contractSigning.timestamp).local();
        if (isBetweenInclusive(eventDate, startDate, endDate)) {
          totalContractSignings += 1;
          bonusSum += entry.contractSigning.bonus;
        }
      }
    });

    return {
      caller: caller.caller,
      totalOffersSent,
      totalWebsiteLinksSent, // Dodane nowe pole
      totalSends,
      totalLinksOpened,
      totalUserSignups,
      totalIdentityVerifications,
      totalCompanyVerifications,
      totalOfferSubmissions,
      totalContractSignings,
      bonusSum,
    };
  });
};

/**
 * Grupuje wszystkie eventy (dla wielu typów) po dacie (YYYY-MM-DD) – sumujemy wszystkie zdarzenia z wszystkich callerów.
 * Konwertujemy każdy timestamp z UTC do lokalnego czasu.
 */
const groupAllEventsByDate = (callers: CallerData[], startDate: dayjs.Dayjs, endDate: dayjs.Dayjs) => {
  const dateMap: Record<string, any> = {};
  for (let d = startDate.startOf('day'); d.isBefore(endDate) || d.isSame(endDate); d = d.add(1, 'day')) {
    const dateStr = d.format('YYYY-MM-DD');
    dateMap[dateStr] = {
      date: dateStr,
      linkClicks: 0,
      userSignups: 0,
      identityVerifications: 0,
      companyVerifications: 0,
      offerSubmissions: 0,
      contractSignings: 0,
      resent: 0,
      offersSent: 0,
      websiteLinksSent: 0, // Nowe pole
    };
  }
  callers.forEach((caller) => {
    const entries = { ...caller.newUsers, ...caller.existingUsers };
    Object.values(entries).forEach((entry) => {
      // Rejestracja kampanii - oferty lub linki do strony
      const campaignDateStr = dayjs.utc(entry.campaignData.timestamp).local().format('YYYY-MM-DD');
      if (dateMap[campaignDateStr]) {
        if (entry.campaignData.utmSource === 'website' || entry.campaignData.utmCampaign === 'direct_call_website') {
          dateMap[campaignDateStr].websiteLinksSent += 1;
        } else {
          dateMap[campaignDateStr].offersSent += 1;
        }
      }

      // Rejestracja eventów
      if (entry.linkClick && entry.linkClick.value) {
        const dateStr = dayjs.utc(entry.linkClick.timestamp).local().format('YYYY-MM-DD');
        if (dateMap[dateStr]) dateMap[dateStr].linkClicks += 1;
      }
      if (entry.accountCreation && entry.accountCreation.value) {
        const dateStr = dayjs.utc(entry.accountCreation.timestamp).local().format('YYYY-MM-DD');
        if (dateMap[dateStr]) dateMap[dateStr].userSignups += 1;
      }
      if (entry.identityVerification && entry.identityVerification.value) {
        const dateStr = dayjs.utc(entry.identityVerification.timestamp).local().format('YYYY-MM-DD');
        if (dateMap[dateStr]) dateMap[dateStr].identityVerifications += 1;
      }
      if (entry.companyVerification && entry.companyVerification.value) {
        const dateStr = dayjs.utc(entry.companyVerification.timestamp).local().format('YYYY-MM-DD');
        if (dateMap[dateStr]) dateMap[dateStr].companyVerifications += 1;
      }
      if (entry.offerSubmission && entry.offerSubmission.value) {
        const dateStr = dayjs.utc(entry.offerSubmission.timestamp).local().format('YYYY-MM-DD');
        if (dateMap[dateStr]) dateMap[dateStr].offerSubmissions += 1;
      }
      if (entry.contractSigning && entry.contractSigning.value) {
        const dateStr = dayjs.utc(entry.contractSigning.timestamp).local().format('YYYY-MM-DD');
        if (dateMap[dateStr]) dateMap[dateStr].contractSignings += 1;
      }
      if (entry.sentAgain) {
        // Dla każdego "resent" dodajemy 1 (jeden event)
        const dateStr = dayjs.utc(entry.campaignData.timestamp).local().format('YYYY-MM-DD');
        if (dateMap[dateStr]) dateMap[dateStr].resent += 1;
      }
    });
  });
  return Object.values(dateMap).sort((a, b) => a.date.localeCompare(b.date));
};

/**
 * Grupuje Bonus Sum per Caller – zwraca strukturę: [{ date, caller1: bonus, caller2: bonus, ... }, ...]
 */
const groupBonusByDateByCaller = (callers: CallerData[], startDate: dayjs.Dayjs, endDate: dayjs.Dayjs) => {
  const dateMap: Record<string, any> = {};
  for (let d = startDate.startOf('day'); d.isBefore(endDate) || d.isSame(endDate); d = d.add(1, 'day')) {
    const dateStr = d.format('YYYY-MM-DD');
    dateMap[dateStr] = { date: dateStr };
    callers.forEach((caller) => {
      dateMap[dateStr][caller.caller] = 0;
    });
  }
  callers.forEach((caller) => {
    const entries = { ...caller.newUsers, ...caller.existingUsers };
    Object.values(entries).forEach((entry) => {
      [
        'linkClick',
        'accountCreation',
        'identityVerification',
        'companyVerification',
        'offerSubmission',
        'contractSigning',
      ].forEach((key) => {
        if (entry[key] && entry[key].value) {
          const dateStr = dayjs.utc(entry[key].timestamp).local().format('YYYY-MM-DD');
          if (dateMap[dateStr]) {
            dateMap[dateStr][caller.caller] += entry[key].bonus;
          }
        }
      });
    });
  });
  return Object.values(dateMap).sort((a, b) => a.date.localeCompare(b.date));
};

/**
 * Grupuje Offers Sent per Caller – analogicznie, każdy user = 1 oferta.
 */
const groupOffersByDateByCaller = (callers: CallerData[], startDate: dayjs.Dayjs, endDate: dayjs.Dayjs) => {
  const dateMap: Record<string, any> = {};
  for (let d = startDate.startOf('day'); d.isBefore(endDate) || d.isSame(endDate); d = d.add(1, 'day')) {
    const dateStr = d.format('YYYY-MM-DD');
    dateMap[dateStr] = { date: dateStr };
    callers.forEach((caller) => {
      dateMap[dateStr][caller.caller] = 0;
    });
  }
  callers.forEach((caller) => {
    const entries = { ...caller.newUsers, ...caller.existingUsers };
    Object.values(entries).forEach((entry) => {
      const dateStr = dayjs.utc(entry.campaignData.timestamp).local().format('YYYY-MM-DD');
      if (dateMap[dateStr]) {
        dateMap[dateStr][caller.caller] += 1;
      }
    });
  });
  return Object.values(dateMap).sort((a, b) => a.date.localeCompare(b.date));
};

/**
 * Definicja opcji dla dynamicznego wyboru eventów w tabeli konwersji.
 */
const eventOptions = [
  { value: 'totalLinksOpened', label: 'Link Clicks' },
  { value: 'totalUserSignups', label: 'User Signups' },
  { value: 'totalIdentityVerifications', label: 'Identity Verifications' },
  { value: 'totalCompanyVerifications', label: 'Company Verifications' },
  { value: 'totalOfferSubmissions', label: 'Offer Submissions' },
  { value: 'totalContractSignings', label: 'Contract Signings' },
  { value: 'totalSends', label: 'Sends' },
];

export const CallersStatistics: React.FC = () => {
  const data = useGetCallersStatistics();
  // Kontrola przedziału czasowego
  const [timeframe, setTimeframe] = useState<string>('day');
  const [startTimeframe, setStartTimeframe] = useState<string>(dayjs().format('YYYY-MM-DD'));
  const [endTimeframe, setEndTimeframe] = useState<string>(dayjs().format('YYYY-MM-DD'));
  // Wyszukiwanie w poszczególnych tabelach
  const [searchSummary, setSearchSummary] = useState<string>('');
  const [searchDetails, setSearchDetails] = useState<string>('');
  const [searchConversion, setSearchConversion] = useState<string>('');
  // Pagina – ustawienie pageSize na 5
  const paginationConfig: PaginationProps = { pageSize: 5 };

  // Dwa selecty do dynamicznego wyboru eventów dla tabeli konwersji
  const [leftEvent, setLeftEvent] = useState<string>('totalLinksOpened');
  const [rightEvent, setRightEvent] = useState<string>('totalUserSignups');

  if (!data) return <Spin />;

  let startDate: dayjs.Dayjs, endDate: dayjs.Dayjs;
  if (timeframe === 'custom') {
    startDate = dayjs(startTimeframe, 'YYYY-MM-DD').startOf('day');
    endDate = dayjs(endTimeframe, 'YYYY-MM-DD').endOf('day');
  } else if (timeframe === 'day') {
    startDate = dayjs().startOf('day');
    endDate = dayjs().endOf('day');
  } else if (timeframe === 'week') {
    startDate = dayjs().startOf('isoWeek');
    endDate = dayjs().endOf('isoWeek');
  } else if (timeframe === 'month') {
    startDate = dayjs().startOf('month');
    endDate = dayjs().endOf('month');
  } else {
    startDate = dayjs().startOf('day');
    endDate = dayjs().endOf('day');
  }

  const statistics = calculateStatistics(data, startDate, endDate);
  const filteredStatistics = statistics.filter((stat) =>
    stat.caller.toLowerCase().includes(searchSummary.toLowerCase())
  );

  const campaignDetails = data
    .flatMap((caller) =>
      Object.entries({ ...caller.newUsers, ...caller.existingUsers })
        .filter(([, entry]) => {
          const campaignDate = dayjs.utc(entry.campaignData.timestamp).local();
          return isBetweenInclusive(campaignDate, startDate, endDate);
        })
        .map(([, entry]) => ({
          caller: caller.caller,
          user: entry.campaignData.utmId,
          offer: entry.campaignData.utmSource,
          campaignType:
            entry.campaignData.utmSource === 'website' || entry.campaignData.utmCampaign === 'direct_call_website'
              ? 'Website'
              : 'Offer',
          role:
            entry.campaignData.utmCampaign === 'direct_call_tenant'
              ? 'Tenant'
              : entry.campaignData.utmCampaign === 'direct_call_landlord'
              ? 'Landlord'
              : entry.campaignData.utmCampaign === 'direct_call_website'
              ? 'Website'
              : 'Unknown',
          linkClick: entry.linkClick?.value ? '✅' : '❌',
          accountCreation: entry.accountCreation?.value ? '✅' : '❌',
          identityVerification: entry.identityVerification?.value ? '✅' : '❌',
          companyVerification: entry.companyVerification?.value ? '✅' : '❌',
          offerSubmission: entry.offerSubmission?.value ? '✅' : '❌',
          contractSigning: entry.contractSigning?.value ? '✅' : '❌',
          sentAgain: entry.sentAgain || 0,
        }))
    )
    .filter((item) => item.caller.toLowerCase().includes(searchDetails.toLowerCase()))
    .sort((a, b) => a.caller.localeCompare(b.caller));

  // Obliczamy dane do tabeli konwersji – dla każdego dzwoniącego obliczamy: (rightEvent / leftEvent) * 100
  const conversionTableData = statistics
    .filter((stat) => stat.caller.toLowerCase().includes(searchConversion.toLowerCase()))
    .map((stat) => {
      const leftValue = stat[leftEvent] || 0;
      const rightValue = stat[rightEvent] || 0;
      const conversion = leftValue > 0 ? Number(((rightValue / leftValue) * 100).toFixed(2)) : 0;
      return {
        caller: stat.caller,
        conversion,
      };
    })
    .sort((a, b) => a.caller.localeCompare(b.caller));

  return (
    <div style={{ padding: 20 }}>
      {/* Kontrola przedziału dat */}
      <div style={{ marginBottom: 20, display: 'flex', gap: 10, flexWrap: 'wrap' }}>
        <Select
          value={timeframe}
          onChange={(value) => setTimeframe(value)}
          style={{ width: timeframe === 'custom' ? 230 : 120 }}
          placeholder="Select timeframe">
          <Option value="day">Day</Option>
          <Option value="week">Week</Option>
          <Option value="month">Month</Option>
          <Option value="custom">Custom</Option>
        </Select>
        {timeframe === 'custom' && (
          <div>
            <input
              type="date"
              placeholder="Start Date"
              value={startTimeframe}
              onChange={(e) => setStartTimeframe(e.target.value)}
            />
            <span className="mx-2">-</span>
            <input
              type="date"
              placeholder="End Date"
              value={endTimeframe}
              onChange={(e) => setEndTimeframe(e.target.value)}
            />
          </div>
        )}
      </div>

      {/* Tabela Podsumowania */}
      <Card
        title="Callers Summary"
        extra={
          <Input
            placeholder="Search Caller"
            value={searchSummary}
            onChange={(e) => setSearchSummary(e.target.value)}
            style={{ width: 200 }}
          />
        }
        style={{ marginBottom: 20 }}>
        <Table
          dataSource={filteredStatistics}
          columns={[
            {
              title: 'Caller',
              dataIndex: 'caller',
              key: 'caller',
              sorter: (a, b) => a.caller.localeCompare(b.caller),
            },
            {
              title: 'Total Offers Sent',
              dataIndex: 'totalOffersSent',
              key: 'totalOffersSent',
              sorter: (a, b) => a.totalOffersSent - b.totalOffersSent,
            },
            {
              title: 'Website Links Sent',
              dataIndex: 'totalWebsiteLinksSent',
              key: 'totalWebsiteLinksSent',
              sorter: (a, b) => a.totalWebsiteLinksSent - b.totalWebsiteLinksSent,
            },
            {
              title: 'Links Opened',
              dataIndex: 'totalLinksOpened',
              key: 'totalLinksOpened',
              sorter: (a, b) => a.totalLinksOpened - b.totalLinksOpened,
            },
            {
              title: 'User Signups',
              dataIndex: 'totalUserSignups',
              key: 'totalUserSignups',
              sorter: (a, b) => a.totalUserSignups - b.totalUserSignups,
            },
            {
              title: 'Identity Verifications',
              dataIndex: 'totalIdentityVerifications',
              key: 'totalIdentityVerifications',
              sorter: (a, b) => a.totalIdentityVerifications - b.totalIdentityVerifications,
            },
            {
              title: 'Company Verifications',
              dataIndex: 'totalCompanyVerifications',
              key: 'totalCompanyVerifications',
              sorter: (a, b) => a.totalCompanyVerifications - b.totalCompanyVerifications,
            },
            {
              title: 'Offer Submissions',
              dataIndex: 'totalOfferSubmissions',
              key: 'totalOfferSubmissions',
              sorter: (a, b) => a.totalOfferSubmissions - b.totalOfferSubmissions,
            },
            {
              title: 'Contract Signings',
              dataIndex: 'totalContractSignings',
              key: 'totalContractSignings',
              sorter: (a, b) => a.totalContractSignings - b.totalContractSignings,
            },
            {
              title: 'Sends',
              dataIndex: 'totalSends',
              key: 'totalSends',
              sorter: (a, b) => a.totalSends - b.totalSends,
            },
            {
              title: 'Bonus (zł)',
              dataIndex: 'bonusSum',
              key: 'bonusSum',
              sorter: (a, b) => a.bonusSum - b.bonusSum,
            },
          ]}
          rowKey="caller"
          pagination={{ ...paginationConfig }}
        />
      </Card>

      {/* Tabela Szczegółów Kampanii */}
      <Card
        title="Campaign Details"
        extra={
          <Input
            placeholder="Search Caller"
            value={searchDetails}
            onChange={(e) => setSearchDetails(e.target.value)}
            style={{ width: 200 }}
          />
        }
        style={{ marginBottom: 20 }}>
        <Table
          dataSource={campaignDetails}
          columns={[
            {
              title: 'Caller',
              dataIndex: 'caller',
              key: 'caller',
              sorter: (a, b) => a.caller.localeCompare(b.caller),
            },
            {
              title: 'User',
              dataIndex: 'user',
              key: 'user',
              sorter: (a, b) => a.user.localeCompare(b.user),
            },
            {
              title: 'Offer/Source',
              dataIndex: 'offer',
              key: 'offer',
              sorter: (a, b) => a.offer.localeCompare(b.offer),
            },
            {
              title: 'Campaign Type',
              dataIndex: 'campaignType',
              key: 'campaignType',
              sorter: (a, b) => a.campaignType.localeCompare(b.campaignType),
            },
            {
              title: 'Role',
              dataIndex: 'role',
              key: 'role',
              sorter: (a, b) => a.role.localeCompare(b.role),
            },
            { title: 'Link Opened', dataIndex: 'linkClick', key: 'linkClick' },
            { title: 'User Signed Up', dataIndex: 'accountCreation', key: 'accountCreation' },
            {
              title: 'Identity Verification',
              dataIndex: 'identityVerification',
              key: 'identityVerification',
            },
            {
              title: 'Company Verification',
              dataIndex: 'companyVerification',
              key: 'companyVerification',
            },
            {
              title: 'Offer Submission',
              dataIndex: 'offerSubmission',
              key: 'offerSubmission',
            },
            { title: 'Contract Signing', dataIndex: 'contractSigning', key: 'contractSigning' },
            { title: 'Resent', dataIndex: 'sentAgain', key: 'sentAgain' },
          ]}
          rowKey="user"
          pagination={{ ...paginationConfig }}
        />
      </Card>

      {/* Tabela Konwersji z dynamicznymi selectami */}
      <Card
        title="Conversion Rates"
        extra={
          <>
            <Select value={leftEvent} onChange={(value) => setLeftEvent(value)} style={{ width: 200, marginRight: 10 }}>
              {eventOptions.map((opt) => (
                <Option key={opt.value} value={opt.value}>
                  {opt.label}
                </Option>
              ))}
            </Select>
            <Select
              value={rightEvent}
              onChange={(value) => setRightEvent(value)}
              style={{ width: 200, marginRight: 10 }}>
              {eventOptions.map((opt) => (
                <Option key={opt.value} value={opt.value}>
                  {opt.label}
                </Option>
              ))}
            </Select>
            <Input
              placeholder="Search Caller"
              value={searchConversion}
              onChange={(e) => setSearchConversion(e.target.value)}
              style={{ width: 200 }}
            />
          </>
        }
        style={{ marginBottom: 20 }}>
        <Table
          dataSource={conversionTableData}
          columns={[
            {
              title: 'Caller',
              dataIndex: 'caller',
              key: 'caller',
              sorter: (a, b) => a.caller.localeCompare(b.caller),
            },
            {
              title: 'Conversion (%)',
              dataIndex: 'conversion',
              key: 'conversion',
              sorter: (a, b) => a.conversion - b.conversion,
            },
          ]}
          rowKey="caller"
          pagination={{ ...paginationConfig }}
        />
      </Card>

      {/* Wykresy – oryginalne wykresy pozostają */}
      <Row gutter={[16, 16]}>
        {/* Daily Events Chart – zawiera wszystkie typy zdarzeń */}
        <Col span={12}>
          <Card title="Daily Events">
            <ResponsiveContainer width="100%" height={300}>
              <LineChart data={groupAllEventsByDate(data, startDate, endDate)}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="date" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Line type="monotone" dataKey="offersSent" stroke="#1abc9c" name="Offers Sent" />
                <Line type="monotone" dataKey="websiteLinksSent" stroke="#e74c3c" name="Website Links Sent" />
                <Line type="monotone" dataKey="linkClicks" stroke="#8884d8" name="Link Clicks" />
                <Line type="monotone" dataKey="userSignups" stroke="#82ca9d" name="User Signups" />
                <Line type="monotone" dataKey="identityVerifications" stroke="#ff7300" name="Identity Verifications" />
                <Line type="monotone" dataKey="companyVerifications" stroke="#387908" name="Company Verifications" />
                <Line type="monotone" dataKey="offerSubmissions" stroke="#a83232" name="Offer Submissions" />
                <Line type="monotone" dataKey="contractSignings" stroke="#3a3a9e" name="Contract Signings" />
                <Line type="monotone" dataKey="resent" stroke="#8e44ad" name="Send" />
              </LineChart>
            </ResponsiveContainer>
          </Card>
        </Col>
        {/* Daily Bonus Sum per Caller */}
        <Col span={12}>
          <Card title="Daily Bonus Sum per Caller">
            <ResponsiveContainer width="100%" height={300}>
              <LineChart data={groupBonusByDateByCaller(data, startDate, endDate)}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="date" />
                <YAxis />
                <Tooltip />
                <Legend />
                {filteredStatistics.map((stat) => (
                  <Line
                    key={stat.caller}
                    type="monotone"
                    dataKey={stat.caller}
                    name={stat.caller}
                    stroke={'#' + Math.floor(Math.random() * 16777215).toString(16)}
                  />
                ))}
              </LineChart>
            </ResponsiveContainer>
          </Card>
        </Col>
        {/* Daily Offers Sent per Caller */}
        <Col span={12}>
          <Card title="Daily Offers Sent per Caller">
            <ResponsiveContainer width="100%" height={300}>
              <LineChart data={groupOffersByDateByCaller(data, startDate, endDate)}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="date" />
                <YAxis />
                <Tooltip />
                <Legend />
                {filteredStatistics.map((stat) => (
                  <Line
                    key={stat.caller}
                    type="monotone"
                    dataKey={stat.caller}
                    name={stat.caller}
                    stroke={'#' + Math.floor(Math.random() * 16777215).toString(16)}
                  />
                ))}
              </LineChart>
            </ResponsiveContainer>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default CallersStatistics;
