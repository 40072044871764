import React, { createContext, ReactNode, useState } from 'react';

import { LinkModal } from './components/LinkModal';
import { SendWebsiteModal } from './components/SendWebsiteModal';

interface CallerContextProps {
  openWebsiteModal: () => void;
  openLinkModal: () => void;
}

export const CallerContext = createContext<CallerContextProps>({
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  openWebsiteModal: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  openLinkModal: () => {},
});

interface CallerProviderProps {
  children: ReactNode;
}

export const CallerProvider: React.FC<CallerProviderProps> = ({ children }) => {
  const [isWebsiteModalOpen, setIsWebsiteModalOpen] = useState(false);
  const [isLinkModalOpen, setIsLinkModalOpen] = useState(false);

  const openWebsiteModal = () => {
    setIsWebsiteModalOpen(true);
  };

  const closeWebsiteModal = () => {
    setIsWebsiteModalOpen(false);
  };

  const openLinkModal = () => {
    setIsLinkModalOpen(true);
  };

  const closeLinkModal = () => {
    setIsLinkModalOpen(false);
  };

  return (
    <CallerContext.Provider
      value={{
        openWebsiteModal,
        openLinkModal,
      }}>
      {children}
      <SendWebsiteModal isOpen={isWebsiteModalOpen} onClose={closeWebsiteModal} />
      <LinkModal isOpen={isLinkModalOpen} onClose={closeLinkModal} />
    </CallerContext.Provider>
  );
};
