import { Button, Form, Input, InputNumber, List, Space, Typography } from 'antd';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next'; // Hook tłumaczeń

import { TransferredKeysAndDevices } from '../../../models/protocol.model';

import { mergeArrays } from './mergeArrays';

interface Props {
  onChange: (data: TransferredKeysAndDevices[]) => void;
  initialValues: TransferredKeysAndDevices[];
}

const initial = [
  {
    name: 'ENTRANCE_DOOR_KEYS',
    quantity: 0,
  },
  {
    name: 'BUILDING_ENTRANCE_DOOR_KEYS',
    quantity: 0,
  },
  {
    name: 'MAILBOX_KEYS',
    quantity: 0,
  },
  {
    name: 'GARAGE_GATE_REMOTE',
    quantity: 0,
  },
];

const TransferedKeysAndDevices: React.FC<Props> = ({ onChange, initialValues }) => {
  const { t } = useTranslation(); // Hook tłumaczeń
  const mergedInitialData = useMemo(() => mergeArrays(initial, initialValues), [initialValues]);
  const [newElement, setNewElement] = useState('');
  const [newValueOfNewElement, setNewValueOfNewElement] = useState(0);
  const [transferredKeysAndDevices, setTransferredKeysAndDevices] = useState(mergedInitialData);

  const handleAdd = () => {
    const newItem = { name: newElement, quantity: newValueOfNewElement };
    setTransferredKeysAndDevices([...transferredKeysAndDevices, newItem]);
    onChange([...transferredKeysAndDevices, newItem]);
    setNewElement('');
    setNewValueOfNewElement(0);
  };

  const handleQuantityChange = (index, value) => {
    const updatedList = [...transferredKeysAndDevices];
    updatedList[index].quantity = value;
    setTransferredKeysAndDevices(updatedList);
    onChange(updatedList);
  };

  const TransferedKeysAndDevicesDisplay = ({ item }: { item: TransferredKeysAndDevices }) => {
    // Znajdź indeks elementu w initial
    const index = initial.findIndex((key) => key.name === item.name);

    return (
      <div>
        {/* Jeśli element jest w initial, wyświetl tłumaczenie */}
        {index !== -1 ? (
          <Typography.Text className="mr-4">{t(`PROTOCOL.D.ITEMS.${item.name}`)}</Typography.Text>
        ) : (
          // Jeśli element nie jest w initial, wyświetl dynamiczną nazwę
          <Typography.Text className="mr-4">{item.name}</Typography.Text>
        )}
      </div>
    );
  };

  return (
    <div className="mt-2">
      <List
        header={<div className="font-medium text-base">{t('PROTOCOL.D.TITLE')}</div>}
        bordered
        dataSource={transferredKeysAndDevices}
        renderItem={(item, index) => (
          <>
            <List.Item className="flex items-center">
              <Space>
                <Typography.Text>{index + 1}</Typography.Text>
                <TransferedKeysAndDevicesDisplay item={item} />
              </Space>
              <Form.Item className="-mb-1 sm:ml-0 md:ml-auto">
                <InputNumber
                  min={0}
                  className="w-14"
                  value={item.quantity}
                  onChange={(value) => handleQuantityChange(index, value)}
                />
              </Form.Item>
            </List.Item>
            {index + 1 === transferredKeysAndDevices.length && (
              <List.Item className="flex">
                <Space>
                  <Typography.Text>{index + 2}</Typography.Text>
                  <Input value={newElement} onChange={(e) => setNewElement(e.target.value)} />
                  <InputNumber
                    min={0}
                    className="w-14"
                    value={newValueOfNewElement}
                    onChange={(value) => setNewValueOfNewElement(value)}
                  />
                </Space>
                <Space className="mt-2 mb-2 flex ml-auto">
                  <Button onClick={handleAdd} type="default" className="float-right w-24">
                    {t('ADD')}
                  </Button>
                </Space>
              </List.Item>
            )}
          </>
        )}
      />
    </div>
  );
};

export default TransferedKeysAndDevices;
