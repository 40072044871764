import { Modal, Popover } from 'antd';
import { Formik } from 'formik';
import { debounce } from 'lodash';
import * as React from 'react';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Button, DatePicker, Form, Input, InputNumber, InputPicker, SelectPicker } from 'rsuite';
import styled from 'styled-components';

import { FormInput } from '../../../components/FormInputs/FormInput';
import { PageHeadingPanel } from '../../../components/Layout/styles';
import { INITIAL_BOUNDS, LeafletMap } from '../../../components/LeafletMap/LeafletMap';
import {
  LeafletEsriGeocodeByClick,
  searchProvider,
} from '../../../components/LeafletMap/components/LeafletEsriGeocodeByClick';
import { GeocodeSearchItem } from '../../../components/LeafletMap/leaflet.model';
import { ToastVariant } from '../../../components/Toast/toast.model';
import { toastService } from '../../../components/Toast/toast.service';
import { firebaseFunctions } from '../../../firebase/firebase';
import { FirebaseCallableFunctions } from '../../../firebase/firebase.models';
import { setProjectOffer } from '../../../firebase/hooks/setProjectOffer';
import { useGetOfferImages } from '../../../hooks/getOfferImages.hook';
import { Offer } from '../../../models/offers.model';
import { gtm } from '../../../reportGTM';
import { useAuth } from '../../Auth/AuthContext';
import { AppRoutes } from '../../Routing/routing.model';
import { getFormattedAddress } from '../hooks/getFormattedAddress';
import { useValidation } from '../hooks/useValidation.hook';

import { ConnectOfferToUser } from './ConnectOfferToUser';
import { usePremisesPurposeSelectOptions } from './FormOptions/usePremisesPurposeSelectOptions.hook';
import { useProvinceOptions } from './FormOptions/useProvince.hook';
import { InputsBox, OfferInputs } from './OfferInputs';
import { SaveButton } from './SaveButton';
import { UploadPhotos } from './uploadPhotos';
import { ValidationErrorsButton } from './validationButton';

const Wrapper = styled.section`
  background-color: ${({ theme }) => theme.backgroundBrokenWhite};
  padding-bottom: 80px;
`;
export const BasicInputs = styled.div`
  margin-top: 32px;
  display: grid;
  grid-template-columns: 1fr auto auto auto auto;
  grid-gap: 16px;
  & > * > * {
    width: 100%;
  }
`;
const AdvancedInputsWrapper = styled.div`
  overflow: hidden;
  margin-left: -20px;
  margin-right: -20px;
  * > {
    margin-top: 16px;
  }
`;
export const FormFooterWrapper = styled.div`
  margin-top: 16px;
  display: flex;
  justify-content: space-between;
`;

const overlay = {
  width: '80%',
  maxWidth: '350px',
};

function getNestedValue(obj, path) {
  return path.split('.').reduce((acc, part) => acc && acc[part], obj);
}

interface Props {
  // TODO: poprawić na type: Offer
  onAddOffers: (values: any) => any;
  isAddingPending: boolean;
  offerId: string;
  initial: Offer;
  hasPermission: boolean;
}
export const OfferForm: React.FC<Props> = ({ onAddOffers, isAddingPending, offerId, initial, hasPermission }) => {
  const { currentUser, isAdmin, isCreator, showSignUpModal, showSignInModal } = useAuth();
  const { t } = useTranslation();
  const domNodeRef = useRef<HTMLDivElement>(null);
  const [domNode, setDomNode] = useState<HTMLDivElement>();
  const premisesPurposeOptions = usePremisesPurposeSelectOptions();
  const provicneOptions = useProvinceOptions();
  const validationSchema = useValidation(t);
  const [searchResult, setSearchResult] = useState<GeocodeSearchItem>();
  const _imagesUrl = useGetOfferImages(offerId);
  const [imagesUrl, setImagesUrl] = useState<string[]>();
  const [saved, setSaved] = useState(true);
  const form = useRef(initial);
  const formRef = useRef(null);
  const history = useHistory();
  const [legalMainUse, setLegalMainUse] = useState(initial.legalMainUse);
  const [surface, setSurface] = useState(initial.areaDetails.surface);
  const [pointMap, setPointMap] = useState<boolean>(true);
  const [submitted, setSubmitted] = useState(false);

  const elements = ['estate', 'houseNumber', 'street', 'district', 'county', 'province'];
  const addressFields = elements.map((element) => `address.officialAddress.${element}`);

  const addressValues = useMemo(
    () => addressFields.map((field) => getNestedValue(form.current, field)),
    [form.current]
  );

  useEffect(() => {
    if (addressValues.every((value) => value !== '')) {
      setPointMap(!pointMap);
      form.current.address.inputText = addressValues.join(', ');
    }
  }, [addressValues]);

  //to jest tylko po to żeby na początku po odświeżeniu mapka nakierowała na poprawny adres
  useEffect(() => {
    searchProvider.results(form.current.address.inputText, null, INITIAL_BOUNDS, (e, s: GeocodeSearchItem[]) => {
      setSearchResult(s[0]);
    });
  }, [pointMap]);

  useEffect(() => {
    setImagesUrl(_imagesUrl);
  }, [_imagesUrl]);

  useEffect(() => {
    domNodeRef && setDomNode(domNodeRef.current);
  }, [domNodeRef]);

  const validateAndSaveForm = async (
    form: { current: { address: { postCode: any; city: any; country: any } } },
    currentUser: { email: any; uid: any },
    searchResult: GeocodeSearchItem,
    setSaved: any,
    toastService: { show: (arg0: string, arg1: string, arg2: { variant: ToastVariant }) => void },
    setProjectOffer: (arg0: any, arg1: any) => void
  ) => {
    // Aktualizacja danych formularza
    form.current = {
      ...formRef.current.values,
      landlord: currentUser.email,
      uid: currentUser.uid,
      geoMarker: searchResult
        ? { lat: searchResult.latlng.lat, lng: searchResult.latlng.lng }
        : formRef.current.values.geoMarker,
      address: searchResult
        ? getFormattedAddress(
            searchResult,
            formRef.current.values.address.plotNumber,
            formRef.current.values.address.officialAddress,
            formRef.current.values.address.ownershipType
          )
        : formRef.current.values.address,
    };

    try {
      setProjectOffer(form.current, setSaved);
    } catch (error) {
      toastService.show(t('FORMS.OFFER_ADD.PLACEHOLDERS.CANNOT_SAVE_FORM'), t('ERROR'), {
        variant: ToastVariant.ERROR,
      });
    }
  };

  const debouncedSave = debounce(validateAndSaveForm, 5000);

  useEffect(() => {
    // wyślij maila informującego o stworzeniu nowej oferty
    if (formRef.current.values?.name && form.current.name === '') {
      const mailNewOffer = firebaseFunctions.httpsCallable(FirebaseCallableFunctions.MAIL_NEW_OFFER);
      mailNewOffer({ offerId: initial.id });
    }

    debouncedSave(form, currentUser, searchResult, setSaved, toastService, setProjectOffer);
    return () => {
      debouncedSave.cancel();
    };
  }, [saved]);

  const onChanges = useCallback(() => {
    if (currentUser) {
      setSaved(false);
    }
  }, []);

  return (
    <Wrapper>
      <SaveButton saved={saved || (!currentUser && true)} />
      <LeafletMap>
        <LeafletEsriGeocodeByClick
          domNode={domNode}
          searchResult={searchResult}
          setSearchResult={setSearchResult}
          initialAddress={form.current.address.inputText}
        />
      </LeafletMap>

      <PageHeadingPanel shaded style={{ zIndex: 1 }}>
        <Formik
          initialValues={form.current}
          validationSchema={validationSchema}
          onSubmit={onAddOffers}
          validateOnMount
          validateOnBlur={true}
          innerRef={formRef}
          disabled={!currentUser || !hasPermission}>
          {({ isSubmitting, isValid, submitForm, validateForm, setTouched }) => (
            <div
              onClick={() => {
                if (!currentUser) {
                  // get from params utm_content=existing_user
                  if (window.location.search.includes('utm_content=existing_user')) {
                    return showSignInModal();
                  } else {
                    return showSignUpModal();
                  }
                } else if (!hasPermission) {
                  return <Modal title={t('NO_ACCESS')} />;
                }
              }}>
              <Form
                disabled={!currentUser}
                onSubmit={async (e) => {
                  validateForm().then((errors) => {
                    setSubmitted(true);
                    if (Object.keys(errors).length > 0) {
                      setTouched({}); // Oznacz wszystkie pola i waliduj
                    } else {
                      onAddOffers(e);
                    }
                  });
                }}
                onChange={() => {
                  onChanges();
                }}>
                <InputsBox style={{ marginTop: '32px' }}>
                  <FormInput
                    submitted={submitted}
                    label={t('ADDRESS.PROVINCE')}
                    name={'address.officialAddress.province'}
                    inputAccepter={SelectPicker}
                    data={provicneOptions}
                  />
                  <FormInput
                    submitted={submitted}
                    label={t('ADDRESS.COUNTY')}
                    name={'address.officialAddress.county'}
                  />
                  <FormInput
                    submitted={submitted}
                    label={t('ADDRESS.DISTRICT')}
                    name={'address.officialAddress.district'}
                  />
                </InputsBox>
                <InputsBox>
                  <FormInput
                    submitted={submitted}
                    label={t('ADDRESS.ESTATE')}
                    name={'address.officialAddress.estate'}
                  />
                  <FormInput
                    submitted={submitted}
                    label={t('ADDRESS.STREET')}
                    name={'address.officialAddress.street'}
                  />
                  <FormInput
                    submitted={submitted}
                    label={t('ADDRESS.HOUSE_NUMBER')}
                    name={'address.officialAddress.houseNumber'}
                  />
                </InputsBox>

                <hr />
                <InputsBox style={{ marginTop: '24px', display: 'flex', width: '110%' }}>
                  <div style={{ flex: '1' }}>
                    <Popover
                      trigger="hover"
                      placement="topLeft"
                      overlayStyle={overlay}
                      content={
                        <div className="font-semibold">
                          {t('FORMS.OFFER_ADD.POPOVERS.ACCORDING_TO_OCCUPANCY_PERMIT')}
                        </div>
                      }>
                      <FormInput
                        submitted={submitted}
                        label={t('DETAILS.LEGAL.MAIN_USE')}
                        name={'legalMainUse'}
                        inputAccepter={InputPicker}
                        data={premisesPurposeOptions}
                        defaultValue={premisesPurposeOptions[2].value}
                        block
                        placeholder={t('SELECT')}
                        style={{ flexGrow: 1, width: '300px' }}
                        onChange={(v) => {
                          formRef.current.values.legalMainUse = v;
                          setLegalMainUse(v);
                          form.current.legalMainUse != formRef.current.values.legalMainUse
                            ? (formRef.current.values.relatedDetails = {})
                            : null;
                        }}
                      />
                    </Popover>
                  </div>
                  <div style={{ flex: '1' }}>
                    <Popover
                      trigger="hover"
                      placement="topLeft"
                      overlayStyle={overlay}
                      content={
                        <div className="font-semibold">{t('FORMS.OFFER_ADD.POPOVERS.RENTAL_AREA_EXCLUSIVE')}</div>
                      }>
                      <FormInput
                        submitted={submitted}
                        label={t('DETAILS.SURFACE.USABLE_AREA_FOR_RENT')}
                        name="areaDetails.surface"
                        inputAccepter={InputNumber}
                        postfix="m&sup2;"
                        min={5}
                        step={10}
                        style={{ flexGrow: 1 }}
                        block
                        onChange={(v) => {
                          formRef.current.values.areaDetails.surface = v;
                          setSurface(v);
                        }}
                      />
                    </Popover>
                  </div>
                  <div style={{ flex: '1' }}>
                    <Popover
                      trigger="hover"
                      placement="topLeft"
                      overlayStyle={overlay}
                      content={<div className="font-semibold">{t('FORMS.OFFER_ADD.POPOVERS.NET_AMOUNT')}</div>}>
                      <FormInput
                        submitted={submitted}
                        label={t('DETAILS.FINANCIAL.RENT_NET')}
                        name={'price'}
                        inputAccepter={InputNumber}
                        postfix="PLN"
                        min={1}
                        step={100}
                        style={{ flexGrow: 1 }}
                        block
                      />
                    </Popover>
                  </div>
                </InputsBox>

                <BasicInputs style={{ width: '103%' }}>
                  <Popover
                    trigger="hover"
                    placement="topLeft"
                    overlayStyle={overlay}
                    content={<div className="font-semibold">{t('FORMS.OFFER_ADD.POPOVERS.USE_WORDS_TO_PROMOTE')}</div>}>
                    <FormInput
                      submitted={submitted}
                      label={t('DETAILS.ADVERTISEMENT_NAME')}
                      name={'name'}
                      style={{ width: '100%' }}
                    />
                  </Popover>
                  <Popover
                    trigger="hover"
                    placement="topLeft"
                    overlayStyle={overlay}
                    content={<div className="font-semibold">{t('FORMS.OFFER_ADD.POPOVERS.AVAILABLE_FROM')}</div>}>
                    <FormInput
                      submitted={submitted}
                      label={t('FORMS.OFFER_ADD.NAMES.OFFER_VALID_FROM')}
                      name={'availability'}
                      inputAccepter={DatePicker}
                      oneTap={true}
                      isoWeek={true}
                    />
                  </Popover>
                  <Popover
                    trigger="hover"
                    placement="topLeft"
                    overlayStyle={overlay}
                    content={
                      <div className="font-semibold">{t('FORMS.OFFER_ADD.POPOVERS.NOT_MANDATORY_IF_RENT_ONLY')}</div>
                    }>
                    <FormInput
                      submitted={submitted}
                      label={t('FORMS.OFFER_ADD.NAMES.OFFER_VALID_TO')}
                      name={'availabilityTo'}
                      inputAccepter={DatePicker}
                      oneTap={true}
                      isoWeek={true}
                    />
                  </Popover>
                </BasicInputs>
                <AdvancedInputsWrapper>
                  <OfferInputs legalMainUse={legalMainUse} surface={surface} setSaved={setSaved} submitted={submitted}>
                    <BasicInputs style={{ margin: '16px' }}>
                      <Popover
                        trigger="hover"
                        placement="topLeft"
                        overlayStyle={overlay}
                        content={
                          <div className="font-semibold">{t('FORMS.OFFER_ADD.POPOVERS.WRITE_PROFESSIONAL_INFO')}</div>
                        }>
                        <FormInput
                          submitted={submitted}
                          label={t('FORMS.OFFER_ADD.NAMES.DESCRIPTION')}
                          name={'description'}
                          inputAccepter={Input}
                          placeholder={t('FORMS.OFFER_ADD.PLACEHOLDERS.ENTER_DESCRIPTION')}
                          componentClass="textarea"
                          rows={5}
                          style={{ width: '105%' }}
                          maxLength="400"
                        />
                      </Popover>
                    </BasicInputs>
                  </OfferInputs>
                </AdvancedInputsWrapper>
                <UploadPhotos urls={imagesUrl} setUrls={setImagesUrl} id={offerId} />
                <FormFooterWrapper>
                  <Button
                    appearance="default"
                    onClick={() => {
                      gtm('offer_preview');
                      validateForm().then((errors) => {
                        if (Object.keys(errors).length === 0) {
                          history.push({
                            pathname: AppRoutes.OFFER.replace(':id', offerId),
                            state: { images: imagesUrl },
                          });
                        } else {
                          console.log('validation errors', errors);
                          toastService.show('Źle uzupełniony formularz', 'Błąd', { variant: ToastVariant.ERROR });
                        }
                      });
                    }}
                    style={{ display: 'block', marginBottom: '10px' }}
                    disabled={isSubmitting || !isValid || !saved}>
                    {t('FORMS.OFFER_ADD.BUTTONS.OFFER_PREVIEW')}
                  </Button>
                  <div
                    onClick={() => {
                      submitForm();
                      gtm('submit_offer_project', { origin: 'offer' });
                    }}>
                    <ValidationErrorsButton
                      validateForm={validateForm}
                      onAddOffers={onAddOffers}
                      isAddingPending={isAddingPending}
                      isSubmitting={isSubmitting}
                      saved={saved}
                      onChanges={onChanges}
                    />
                  </div>
                </FormFooterWrapper>
              </Form>
            </div>
          )}
        </Formik>
        {(isAdmin || isCreator) && <ConnectOfferToUser offerId={offerId} />}
      </PageHeadingPanel>
    </Wrapper>
  );
};
