import { Space, Spin } from 'antd';
import * as React from 'react';
import { useLocation } from 'react-router';

import { useGetOffer } from '../../firebase/hooks/getOfferById.hook';
import { useGetOfferImages } from '../../hooks/getOfferImages.hook';
import { OfferStatusState } from '../../models/offers.model';
import { App } from '../App/App';

import { OfferView } from './OfferView';

interface Props {
  id: string;
  preview?: boolean;
}

interface LocationState {
  images?: string[];
}

export const Offer: React.FC<Props> = ({ id, preview }) => {
  const [offer] = useGetOffer(id);

  const location = useLocation<LocationState>();
  const imagesUrl = location.state?.images || useGetOfferImages(id);

  if (offer?.offerStatus.state !== OfferStatusState.ACTIVE) {
    preview = true;
  }

  return offer ? (
    <OfferView offer={offer} images={imagesUrl} preview={preview} />
  ) : (
    <App>
      <Space size="middle" className="absolute top-1/2 right-1/2">
        <Spin />
      </Space>
    </App>
  );
};
