import { PushpinOutlined } from '@ant-design/icons';
import { Image, Skeleton } from 'antd';
import { fabric } from 'fabric';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactSortable } from 'react-sortablejs';
import { v4 as uuidv4 } from 'uuid';

import { PanelContent, PanelWhite } from '../../../components/Layout/styles';
import { firebaseStorage } from '../../../firebase/firebase';
import { SHOW_LOGS } from '../../App/App';

const handleUpload = async (file: File, id: string, order: number): Promise<string> => {
  try {
    const splittedFileName = file.name.split('.');
    const fileExtension = splittedFileName[splittedFileName.length - 1];
    const fileName = `${order}_${uuidv4()}.${fileExtension}`;
    const imageRef = firebaseStorage.ref(`/offers/${id}/images/${fileName}`);

    // Wczytaj plik jako buffer
    const buffer = await file.arrayBuffer();

    // Konwersja buffer na Blob
    const blob = new Blob([buffer]);

    // Odczyt obrazów za pomocą fabric.js
    const image = await new Promise<fabric.Image>((resolve) => {
      fabric.Image.fromURL(
        URL.createObjectURL(blob),
        (img) => {
          resolve(img);
        },
        { crossOrigin: 'Anonymous' }
      );
    });

    const watermark = await new Promise<fabric.Image>((resolve) => {
      fabric.Image.fromURL(
        `/images/logo/Logo-black.png`,
        (img) => {
          resolve(img);
        },
        { crossOrigin: 'Anonymous' }
      );
    });

    // Utwórz canvas i dodaj obraz oraz watermark
    const canvas = new fabric.Canvas(null, { width: image.width, height: image.height });
    canvas.add(image);

    // Stała pozycja watermarka - prawy dolny róg z marginesem
    const marginX = 20;
    const marginY = 20;

    watermark.scaleToWidth(image.width * 0.2);
    watermark.scaleToHeight(image.height * 0.2);
    watermark.set({
      left: image.width - watermark.getScaledWidth() - marginX,
      top: image.height - watermark.getScaledHeight() - marginY,
      opacity: 0.5,
    });
    canvas.add(watermark);

    // Konwersja canvas na Blob
    const dataUrl = canvas.toDataURL({ format: 'jpeg', quality: 0.8 });
    const watermarkedBlob = await (await fetch(dataUrl)).blob();

    // Prześlij obraz z watermarkiem do Firebase Storage
    const snapshot = await imageRef.put(watermarkedBlob, {
      contentType: file.type,
    });
    const downloadUrl = await snapshot.ref.getDownloadURL();

    return downloadUrl;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const handleDelete = async (url: string): Promise<void> => {
  try {
    const fileRef = firebaseStorage.refFromURL(url);
    await fileRef.delete();

    // Usuń kopię z folderu "small/"
    const filePath = fileRef.fullPath;
    const smallFilePath = filePath.replace(/(.*\/)([^/]+)$/, '$1small/small_$2');
    const smallFileRef = firebaseStorage.ref(smallFilePath);

    await smallFileRef.delete();
  } catch (error) {
    console.error('Error deleting file:', error);
    throw error;
  }
};

interface Props {
  setUrls: Dispatch<SetStateAction<string[]>>;
  urls: string[];
  id: string;
  onUploadComplete?: () => void;
}

interface ItemInterface {
  id: string;
  url: string;
  label: string;
}

export const UploadPhotos: React.FC<Props> = ({ setUrls, urls, id, onUploadComplete }) => {
  const [items, setItems] = useState<ItemInterface[]>([]);
  const [reordering, setReordering] = useState(false);
  const { t } = useTranslation();

  const handleSetImageAsFirst = async (url: string) => {
    // Jeśli obraz jest już pierwszy, nie rób nic
    if (items[0]?.url === url) return;

    // Znajdź element odpowiadający wskazanemu URL
    const selectedItem = items.find((item) => item.url === url);
    if (!selectedItem) return;

    // Stwórz nową tablicę, umieszczając wybrany element na początku
    const newItems = [selectedItem, ...items.filter((item) => item.url !== url)];

    // Aktualizuj etykiety i identyfikatory zgodnie z nową kolejnością
    newItems.forEach((item, index) => {
      item.label = `Image ${index + 1}`;
      item.id = `${index + 1}`;
    });

    // Wywołaj funkcję handleReorder, która zajmie się re-uploadem obrazów oraz aktualizacją stanu
    await handleReorder(newItems);
  };

  useEffect(() => {
    if (urls) {
      SHOW_LOGS && console.log(urls);
      setItems(urls.map((url, index) => ({ url, label: `Image ${index + 1}`, id: `${index + 1}` })));
    }
  }, [urls]);

  const handleFileUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    try {
      const files = event.target.files;
      if (!files) return;

      const uploadedUrls = await Promise.all(Array.from(files).map((file) => handleUpload(file, id, files.length + 1)));

      setUrls((prev) => [...prev, ...uploadedUrls]);

      setItems((prevItems) => [
        ...prevItems,
        ...uploadedUrls.map((url, index) => ({
          url,
          label: `Image ${prevItems.length + index + 1}`,
          id: `${index + 1}`,
        })),
      ]);

      if (onUploadComplete) onUploadComplete();
    } catch (error) {
      SHOW_LOGS && console.error(error);
    }
  };

  const handleRemoveImage = (url: string) => {
    SHOW_LOGS && console.log(url);
    handleDelete(url);
    setUrls((prev) => prev.filter((u) => u !== url));
    setItems((prev) => prev.filter((item) => item.url !== url));
  };

  const handleReorder = async (newState: ItemInterface[]) => {
    if (!newState[0] || !items[0]) return;
    if (newState[0].url !== items[0].url) {
      SHOW_LOGS && console.log('Reordering detected, updating files accordingly...');
      setReordering(true);

      // Iteracja po nowej kolejności – pobieramy blob, usuwamy stary plik i ponownie uploadujemy
      for (let i = 0; i < newState.length; i++) {
        const item = newState[i];
        const originalUrl = item.url;

        try {
          const blob = await fetch(originalUrl).then((res) => res.blob());
          await handleDelete(originalUrl);
          const newFileName = `${i + 1}_${uuidv4()}`;
          const newUrl = await handleUpload(new File([blob], newFileName), id, i + 1);

          item.url = newUrl;
          item.id = `${i + 1}`;
          item.label = `Image ${i + 1}`;

          SHOW_LOGS && console.log(`Re-uploaded image to: ${newUrl}`);
        } catch (error) {
          SHOW_LOGS && console.error('Error during re-upload process:', error);
        }
      }
    }

    // Aktualizacja stanu
    setItems(newState);
    setUrls(newState.map((item) => item.url));
    SHOW_LOGS && console.log('Reordering completed:', newState);
    setReordering(false);
  };

  return (
    <PanelWhite header={t('FORMS.OFFER_ADD.NAMES.ATTACH_PHOTOS')} defaultExpanded collapsible>
      <PanelContent className="p-4">
        <input
          type="file"
          accept="image/png, image/jpg, image/jpeg"
          onChange={handleFileUpload}
          multiple
          className="p-2 border border-gray-300 rounded w-full"
        />

        <br />
        <br />

        <ReactSortable
          list={items}
          setList={handleReorder}
          animation={200}
          easing="ease-out"
          className="flex flex-wrap">
          {items.map((item) =>
            reordering ? (
              <Skeleton.Image active={true} key={item.id} className="m-2" style={{ width: '330px', height: '330px' }} />
            ) : (
              <div key={item.id} className="inline-block relative m-2 group">
                <Image width={350} src={item.url} alt={item.label} className="rounded" />
                <button
                  type="button"
                  className="absolute -top-2 -right-2 w-8 h-8 font-bold bg-red-500 text-white rounded-full opacity-0 group-hover:opacity-100 transition-opacity duration-200"
                  onClick={() => handleRemoveImage(item.url)}>
                  X
                </button>
                <button
                  type="button"
                  className="absolute -top-2 -left-2 w-8 h-8 bg-gray-200 rounded-full opacity-0 group-hover:opacity-100 transition-opacity duration-200"
                  onClick={() => handleSetImageAsFirst(item.url)}>
                  <PushpinOutlined />
                </button>
              </div>
            )
          )}
        </ReactSortable>
      </PanelContent>
    </PanelWhite>
  );
};
