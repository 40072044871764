import { ToastVariant } from '../../components/Toast/toast.model';
import { toastService } from '../../components/Toast/toast.service';
import { UpdateInformation } from '../../models/MojaTablica.model';
import { firestore } from '../firebase';
import { FirestoreCollection } from '../firebase.models';

export async function useUpdateUserInformation(
  data: UpdateInformation,
  uid: string,
  translate: (key: string) => string
): Promise<void> {
  try {
    const docRef = firestore.collection(FirestoreCollection.USERS).doc(uid);
    await docRef.update(data);
    toastService.show(translate('SAVE_DATA_SUCCESS'), translate('SUCCESS'));
  } catch (error) {
    toastService.show(translate('SAVE_DATA_FAILED'), translate('ERROR'), { variant: ToastVariant.ERROR });
  }
}
