import React from 'react';

import { App } from '../../../App/App';

import Flows from './Flows';

export const Support: React.FC = () => {
  return (
    <App>
      {' '}
      <div className="flex flex-col">
        {' '}
        <Flows />
      </div>
    </App>
  );
};
