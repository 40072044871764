export function getTerms(): string {
  return `1. Kodeks cywilny, art. 659 do art. 679;
    2. Warunki Standardowe Platformy najmuje.com:
    2.1. Zasady Ogólne:
    2.1.1. Najemca, w ramach niniejszej Umowy Najmu będzie miał prawo do korzystania z
    oznaczonego lokalu użytkowego zwanego również dalej Lokalem, z parkingu dla aut
    osobowych w ilości szt. 2.
    2.1.2. Wynajmujący oddaje Najemcy a Najemca przyjmuje Lokal w najem na zasadach
    określonych niniejszą Umową.
    2.1.3. Lokal zostanie wydany Najemcy w stanie szczegółowo określonym w Protokole
    zdawczo-odbiorczym.
    2.1.4. W przypadku bezzasadnej odmowy dokonania przez Najemcę odbioru Lokalu i nie
    podpisania Protokołu zdawczo-odbiorczego w terminie wskazanym, uznaje się, że odbiór
    Lokalu nastąpił, a Najemca nie zgłasza żadnych zastrzeżeń do Lokalu.
    2.1.5. Poza przypadkiem określonym w ust. 2.1.4. przyjmuje się, że odbiór Lokalu nastąpił także,
    jeżeli Najemca zgłosi uwagi do Lokalu inne niż dotyczące Wad Lokalu.
    2.1.6. Stawka miesięczna Czynszu, podlegać będzie corocznej indeksacji/waloryzacji
    (corocznemu przeliczaniu) w oparciu o wskaźnik określający wzrost cen towarów i usług,
    publikowany przez Prezesa Głównego Urzędu Statystycznego. Strony Umowy
    postanawiają, że mechanizm waloryzacji stawki Czynszu stanowi jedyną dopuszczalną
    podstawę do podwyższenia przez Wynajmującego Czynszu i wyłączają tym samym
    zastosowanie art. 685 K.c.
    2.1.7. O nowej wysokości Czynszu Wynajmujący zawiadomi Najemcę po upływie danego roku
    kalendarzowego i po opublikowaniu przez Prezesa Głównego Urzędu Statystycznego
    wskaźnika opisanego w ust. 2.6.
    2.1.9. Najemca może wypowiedzieć Umowę Najmu natychmiast, w przypadku istnienia Wad
    Lokalu, w rozumieniu Regulaminu, o których Składający Ofertę nie poinformował.
    2.1.10. Zarówno zawarcie Umowy Najmu/Przekazanie Lokalu Najemcy jest uzależnione od
    wpłacenia przez Najemcę Kaucji gwarancyjnej lub innego wymaganego zabezpieczenia.
    Spełnia ono funkcję zabezpieczającą należność z tytułu Umowy Najmu przysługujące
    Wynajmującemu.
    2.1.11. W przypadku stwierdzenia przez Wynajmującego uchybień lub zaniedbań w wymienionych
    obowiązkach Najemcy, Wynajmujący wezwie Najemcę do ich bezzwłocznego usunięcia. W
    nieprzekraczalnym terminie 7 dni od wezwania Najemcy do usunięcia zaniedbań, Najemca
    ma obowiązek ich usunięcia na swój koszt a gdy tego nie zrobi, Wynajmujący może
    dokonać ich sam lub wezwać specjalistyczną firmę do ich naprawienia na koszt Najemcy.
    Koszty w ten sposób powstałe zostaną doliczone Najemcy w kolejnym miesiącu do faktury
    za najem Lokalu. Najemca w tym przypadku będzie miał prawo żądać od Wynajmującego
    okazania kopii faktur/rachunków związanych z przedmiotową naprawą.
    2.1.12. Najemca winien wystąpić i uzyskać pisemną zgodę Wynajmującego w celu umieszczenia
    jakichkolwiek znaków i reklam na zewnątrz Lokalu. Wynajmujący nie odmówi zgody, jeżeli
    znaki takie będą odpowiadały następującym warunkom:
    2.1.12.1. Koszt wykonania takich reklam, jak również koszty energii elektrycznej przez nie
    zużywanej będą pokrywane przez Najemcę, oraz
    2.1.12.2. Najemca zobowiązuje się do wykonania znaków reklamowych zgodnie ze sztuką
    budowlaną i utrzymania ich w należytym stanie, oraz ponosi odpowiedzialność za
    szkody spowodowane przez te znaki.
    2.1.13. Najemca może dokonywać drobnych nakładów i ulepszeń Lokalu w porozumieniu z
    Wynajmującym. Jakiekolwiek inwestycje w Lokalu i prawo do ich rozliczeń z Wynajmującym
    mogą powstać tylko za uprzednią zgodą Wynajmującego wyrażoną w formie pisemnej lub
    elektronicznej. Po rozwiązaniu Umowy Najemca uprawniony będzie do zabrania tylko tych
    nakładów poczynionych w Lokalu przez niego, które dadzą się odłączyć od Lokalu nie
    powodując w nim szkód.
    2.1.14. Jeżeli któreś z postanowień Warunków Umowy byłoby nieważne ze względu na jego
    sprzeczność z bezwzględnie obowiązującym przepisem ustawy, to pozostałe jej
    postanowienia pozostają nienaruszone. W takim przypadku Strony podejmą starania o to,
    aby uzgodnić postanowienie zastępcze, którego cel gospodarczy będzie równoważny lub
    zbliżony do celu postanowienia nieważnego.
    2.1.15. Strony zachowają w tajemnicy istotne warunki ekonomiczne niniejszej Umowy, chyba, że
    ujawnienie tych informacji będzie wymagane na podstawie przepisów lub na żądanie
    właściwych władz oraz z zastrzeżeniem możliwości ujawnienia tych informacji
    pracownikom, doradcom podatkowym i prawnym.
    2.1.16. Wszelkie zmiany i uzupełnienia Umowy wymagają formy dokumentowej pod rygorem
    nieważności i mogą być wprowadzane wyłącznie po uprzednim uzgodnieniu ich treści przez
    Strony.
    2.1.17. Do każdej kwoty należnej jednej ze Stron od drugiej Strony, nie zapłaconej w terminie,
    Strona uprawniona ma prawo doliczyć odsetki umowne maksymalne.
    2.1.18. Wszelkie zawiadomienia i oświadczenia Stron przewidziane w Umowie lub składane w
    związku z Umową będą sporządzone w formie dokumentowej, w tym elektronicznej.
    2.1.19. Jako adresy dla doręczeń Strony wskazują adresy poczty e-mail wskazane w FL:
    2.1.20. Strony są zobowiązane do wzajemnego informowania się tą sama drogą o zmianach
    adresu e-mail dla doręczeń. W przypadku niedochowania powyższego warunku przez
    którąkolwiek ze Stron, doręczenie dokonane przez jedną ze Stron na ostatni znany jej adres
    e-mail drugiej Strony będzie uważane za skuteczne z upływem 14 dni od dnia wysłania
    korespondencji.
    2.2. Obowiązki Najemcy. Strony ustaliły, że Najemca będzie zobowiązany do:
    2.2.1. Terminowego uiszczania Wynajmującemu czynszu i innych należnych Opłat stosownie do
    postanowień niniejszej Umowy;
    2.2.2. Realizacji wszystkich, w tym obciążających właściciela Lokalu, nakazów i ograniczeń
    wydanych przez uprawnione organy (na przykład Sanepid, Państwowa Inspekcja Pracy,
    Urząd Miasta) dotyczących korzystania z Lokalu, o których Najemca został prawidłowo
    poinformowany przez Wynajmującego;
    2.2.3. Zwrotu Wynajmującemu Lokalu po zakończeniu Najmu w stanie niepogorszonym ponad
    normalne zużycie;
    2.2.4. Sprzątania, pielęgnacji i utrzymania Lokalu w dobrym stanie w tym bieżących drobnych
    napraw i remontów urządzeń oraz ruchomości, które używa;
    2.2.5. Najemca umożliwi Wynajmującemu przeprowadzanie okresowych (nie częściej jednak niż
    jeden raz na miesiąc) kontroli stanu Lokalu w zwykłych godzinach pracy Najemcy i za
    uprzednim rozsądnym 1- dniowym powiadomieniem Najemcy;
    2.2.6. Ubezpieczenia mienia i posiadania aktualnej polisy ubezpieczeniowej z tytułu prowadzonej
    działalności gospodarczej, przez cały okres obowiązywania Umowy na kwotę co najmniej
    50.000 zł.
    2.3. Obowiązki Wynajmującego. Wynajmujący jest zobowiązany w szczególności do:
    2.3.1. Ubezpieczenia Budynku wraz z Lokalem od ognia, zalania, powodzi i innych zdarzeń
    losowych;
    2.3.2. Zapewnienia odnowy i naprawy urządzeń technicznych znajdujących się w Lokalu a
    umożliwiających Najemcy korzystanie z energii elektrycznej, centralnego ogrzewania,
    zimnej i ciepłej wody, odprowadzenia ścieków.
    2.4. Rozwiązanie Umowy.
    2.4.1. Wynajmujący może rozwiązać Umowę jednostronnym oświadczeniem woli ze skutkiem
    natychmiastowym, bez terminu wypowiedzenia, jeżeli Najemca rażąco narusza niniejsze
    Warunki Najmu, w szczególności:
    2.4.1.1. Zwłoki w zapłacie Czynszu Najmu za co najmniej dwa pełne okresy płatności
    Czynszu Najmu lub ich równowartość w PLN i pomimo uprzedzenia przez
    Wynajmującego o zamiarze wypowiedzenia Umowy w formie dokumentowej i
    wyznaczenia dodatkowego 7 dniowego terminu do zapłaty, Najemca nie ureguluje
    powstałych zaległości;
    2.4.1.2. Zostanie ogłoszona upadłość Najemcy, stanie się on niewypłacalny lub zostanie
    otwarta jego likwidacja;
    2.4.1.3. Najemca nie wpłaci Kaucji lub nie innego złoży zabezpieczenia Wynajmującemu w
    terminie tam określonym;
    2.4.1.4. Najemca będzie wykorzystywał Lokal niezgodnie z jego przeznaczeniem i pomimo
    wyznaczenia przez Wynajmującego siedmiodniowego terminu do zaprzestania;
    2.4.1.5. Dokonywania zmian w Lokalu naruszających jego konstrukcję bez uprzedniej
    dokumentowej zgody Wynajmującego;
    2.4.1.6. Nie przedłużenia polisy ubezpieczeniowej, pomimo wyznaczenia przez
    Wynajmującego siedmiodniowego terminu do jej przedłożenia;
    2.4.1.7. Podnajmowania Lokalu lub oddania go w bezpłatne używanie osobom trzecim bez
    dokumentowej zgody Wynajmującego;
    2.4.1.8. Najemca złożył fałszywe oświadczenia lub inne dokumenty poświadczające
    nieprawdę bądź nie podał istotnych okoliczności mających wpływ na zawarcie
    Umowy;
    2.4.1.9. Najemca będąc osobą fizyczną bądź członkiem organów Podmiotu został skazany
    prawomocnym wyrokiem w związku z prowadzoną działalnością gospodarczą albo
    zostało zajęte jego mienie w trybie postępowania egzekucyjnego.
    2.4.2. Strony postanawiają, iż w przypadku wystąpienia którejkolwiek z wymienionych w ustępie
    2.4.1 przyczyn rozwiązania Umowy przez Wynajmującego, wystąpienie tych przyczyn lub
    przyczyny uznawane będzie przez Strony jako zawinione przez Najemcę.
    2.4.3. Najemca może rozwiązać Umowę w trybie natychmiastowym w razie rażącego naruszenia
    przez Wynajmującego następujących postanowień Umowy:
    2.4.3.1. Wynajmujący lub osoby, za które ponosi odpowiedzialność utrudniają lub
    uniemożliwiają dostęp do Lokalu, jego pracownikom albo podmiotom
    korzystającym z usług Najemcy;
    2.4.3.2. Niewywiązywania się z Obowiązków wskazanych w ustępie 2.3.
    2.5. Zwrot i Odbiór Lokalu.
    2.5.1. Najemca zobowiązuje się zwrócić Wynajmującemu Lokal, po zakończeniu Umowy Najmu,
    w stanie w jakim go odebrał, z uwzględnieniem stopnia zużycia wskutek prawidłowej
    eksploatacji, opróżniony ze wszystkich urządzeń, wyposażenia, towarów i innych rzeczy
    wniesionych przez Najemcę, z zastrzeżeniem poniższych postanowień.
    2.5.2. Najemca zobowiązany jest w ostatnim dniu obowiązywania Umowy, do zwrotu
    Wynajmującemu Lokal. W dniu zwrotu zostanie sporządzony Protokół zdawczo-odbiorczy
    podpisany przez obie Strony.
    2.5.3. Punkt wyjścia do ustalenia stanu technicznego Lokalu stanowi Protokół zdawczo-odbiorczy
    sporządzony w dniu objęcia Lokalu w najem przez Najemcę.
    2.5.4. Strony uzgodnią termin zwrotu Lokalu, a w przypadku braku porozumienia w tym zakresie,
    termin ten nie będzie dłuższy niż 3 (trzy) dni licząc od dnia rozwiązania Umowy.
    2.5.5. W przypadku rozwiązania Umowy lub innego zakończenia Umowy na jaki została zawarta,
    z winy Najemcy lub przez wszelkie inne osoby lub podmioty, które znalazły się w posiadaniu
    Lokalu za zgodą lub wiedzą Wynajmującego, Wynajmującemu przysługuje od Najemcy
    odszkodowanie w wysokości wpłaconej Kaucji.
    2.5.6. Jeżeli po opuszczeniu Lokalu przez Najemcę w Lokalu pozostaną rzeczy przez niego
    wniesione a Najemca nie odbierze ich w terminie ustalonym przez Strony, pozostawione w
    Lokalu rzeczy traktowane będą jako porzucone przez Najemcę.
    2.5.7. Wynajmujący ma prawo naliczenia kar umownych w wysokości 10% kwoty miesięcznego
    Czynszu najmu, za każdy dzień opóźnienia zwrotu Lokalu przez Najemcę.
    2.5.8. Najemca ma prawo naliczenia kar umownych w wysokości 10% kwoty miesięcznego
    Czynszu najmu, za każdy dzień opóźnienia przekazania Lokalu przez Wynajmującego
    Najemcy.`;
}
