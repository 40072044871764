import { Alert, Button, Form, Input, Space } from 'antd';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ToastVariant } from '../../../../components/Toast/toast.model';
import { toastService } from '../../../../components/Toast/toast.service';
import { firestore } from '../../../../firebase/firebase';
import { FirestoreCollection } from '../../../../firebase/firebase.models';
import { useAuth } from '../../../Auth/AuthContext';

interface EmailInfoProps {
  email: string;
}

export const EmailInfo: React.FC = () => {
  const [componentDisabled, setComponentDisabled] = useState<boolean>(true);
  const { currentUser, updateEmail, sendVerificationEmail } = useAuth();
  const { t } = useTranslation();

  const [email, setEmail] = useState<string>(currentUser.email);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  function onFinish(value: EmailInfoProps): void {
    updateEmail(value.email)
      .then(() => {
        toastService.show(t('CONFIRM_EMAIL_SUCCESS'), t('SUCCESS'));
        sendVerificationEmail()
          .then((response) => {
            if (response.status === 'SUCCESS') {
              toastService.show(t('FEATURES.AUTH.SIGN_UP.VERIFICATION_EMAIL_SENT'), t('CONFIRM_EMAIL'), {
                variant: ToastVariant.SUCCESS,
              });

              const docRef = firestore.collection(FirestoreCollection.USERS).doc(currentUser.uid);
              docRef
                .update(value)
                .then(() => {
                  toastService.show(t('FEATURES.AUTH.PROFILE_UPDATED'), t('SUCCESS'), {
                    variant: ToastVariant.SUCCESS,
                  });
                })
                .catch((e) => {
                  toastService.show(t(e.message || t('UPDATE_PROFILE_ERROR')), t('ERROR'), {
                    variant: ToastVariant.ERROR,
                  });
                  onReset(); // Reset w przypadku błędu aktualizacji
                });
            } else {
              toastService.show(t(response.message), t('ERROR'), { variant: ToastVariant.ERROR });
              onReset(); // Reset w przypadku błędu weryfikacji e-maila
            }
            // show verification email modal
          })
          .catch((e) => {
            toastService.show(t(e.message || t('ERROR_UNEXPECTED')), t('ERROR'), { variant: ToastVariant.ERROR });
            onReset(); // Reset w przypadku błędu weryfikacji e-maila
          });
      })
      .catch((e) => {
        toastService.show(e.message, t('ERROR'), {
          variant: ToastVariant.ERROR,
        });
        onReset();
      });
    setComponentDisabled(!componentDisabled);
  }
  function onReset(): void {
    form.resetFields();
    setEmail(currentUser.email);
  }
  useEffect(() => {
    form.resetFields();
  }, [email]);

  return (
    <>
      {!currentUser.emailVerified ? (
        <Space direction="vertical" style={{ marginBottom: 50 }}>
          <Alert
            style={{ width: '400px' }}
            message={t('CONFIRM_EMAIL')}
            description={t('ACCESS_DENIED')}
            type="warning"
            showIcon
            action={
              <div className="flex flex-col">
                <Button
                  type="primary"
                  danger
                  style={{ backgroundColor: 'orange', color: 'white' }}
                  onClick={() => {
                    setLoading(true);
                    sendVerificationEmail()
                      .then((response) => {
                        if (response.status === 'SUCCESS') {
                          toastService.show(t('FEATURES.AUTH.SIGN_UP.VERIFICATION_EMAIL_SENT'), t('CONFIRM_EMAIL'), {
                            variant: ToastVariant.SUCCESS,
                          });
                        } else {
                          toastService.show(t(response.message), t('ERROR'), { variant: ToastVariant.ERROR });
                        }
                      })
                      .catch((e) => {
                        toastService.show(t(e.message || t('ERROR_UNEXPECTED')), t('ERROR'), {
                          variant: ToastVariant.ERROR,
                        });
                      })
                      .finally(() => setLoading(false));
                  }}
                  loading={loading}>
                  {t('CONFIRM')}
                </Button>
              </div>
            }
          />
        </Space>
      ) : null}
      <Form
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        style={{ maxWidth: 600 }}
        initialValues={{ email: email }}
        autoComplete="off"
        disabled={componentDisabled}
        onFinish={onFinish}
        form={form}>
        <Form.Item label="Email" name="email" rules={[{ required: true, message: t('FIELD_REQUIRED') }]}>
          <Input />
        </Form.Item>
        <Form.Item wrapperCol={{ offset: 8, span: 16 }} hidden={componentDisabled}>
          <Button type="primary" htmlType="submit" style={{ marginRight: 20 }}>
            {t('SAVE')}
          </Button>
          <Button htmlType="button" onClick={onReset}>
            {t('RESET')}
          </Button>
        </Form.Item>
      </Form>
      <Button
        style={{ marginLeft: 120 }}
        onClick={() => {
          setComponentDisabled(!componentDisabled);
        }}
        // hidden={!componentDisabled}
        // Nie można zmienić maila ponieważ powoduje to komplikacje
        hidden={true}>
        Zmień email
      </Button>
    </>
  );
};
