import { Card, Col, Form, Input, Row, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Offer } from '../../../models/offers.model';
import { Equipment, EquipmentCondition } from '../../../models/protocol.model';
import { useOfficeEquipment } from '../../OfferAdd/components/FormOptions/useOfficeEquipment.hook';
import { useWarehouseEquipment } from '../../OfferAdd/components/FormOptions/useWarehouseEquipment.hook';

import { useEquipmentConditionOptions } from './useEquipmentConditionOptions.hook';

interface Props {
  onChange: (data: Equipment[]) => void;
  offer: Offer;
  initialValues: Equipment[];
}

const EstablishedEquipmentState: React.FC<Props> = ({ onChange, offer, initialValues }) => {
  const { t } = useTranslation();
  const conditionOptions = useEquipmentConditionOptions();
  const officeEquipmentOptions = useOfficeEquipment();
  const warehouseEquipmentOptions = useWarehouseEquipment();

  const [equipmentList, setEquipmentList] = useState<Equipment[]>([]);

  useEffect(() => {
    if (offer && offer.relatedDetails) {
      const newEquipmentList: Equipment[] = [];

      ['officeEquipment', 'warehouseEquipment'].forEach((equipmentType) => {
        const equipment = offer.relatedDetails[equipmentType];

        if (equipment) {
          equipment.forEach((item: string, index: number) => {
            newEquipmentList.push({
              name: item,
              condition: initialValues?.[index]?.condition || '', // Ustawienie wartości z initialValues
              quantity: initialValues?.[index]?.quantity || 0, // Ustawienie ilości z initialValues
            });
          });
        }
      });

      setEquipmentList(newEquipmentList);
      onChange(newEquipmentList);
    }
  }, [offer, onChange, initialValues]);

  const handleQuantityChange = (name: string, quantity: number) => {
    const updatedList = equipmentList.map((item) => (item.name === name ? { ...item, quantity } : item));
    setEquipmentList(updatedList);
    onChange(updatedList);
  };

  const handleConditionChange = (name: string, condition: EquipmentCondition) => {
    const updatedList = equipmentList.map((item) => (item.name === name ? { ...item, condition } : item));
    setEquipmentList(updatedList);
    onChange(updatedList);
  };

  // Funkcja, która tłumaczy nazwy wyposażenia
  const getTranslatedEquipmentName = (name: string) => {
    const officeTranslationOption = officeEquipmentOptions.find((option) => option.value === name);
    const warehouseTranslationOption = warehouseEquipmentOptions.find((option) => option.value === name);
    return officeTranslationOption?.label || warehouseTranslationOption?.label || name;
  };

  if (equipmentList.length === 0) {
    return (
      <Card title={<div className="text-base font-medium">{t('PROTOCOL.B.TITLE')}</div>}>
        <Form layout="vertical">
          <div className="font-medium py-4 text-center">{t('PROTOCOL.B.NO_EQUIPMENT')}</div>
        </Form>
      </Card>
    );
  }

  return (
    <Card title={<div className="text-base font-medium">{t('PROTOCOL.B.TITLE')}</div>}>
      <Form layout="vertical">
        <div className="font-medium pb-4">
          <Row>
            <Col span={8}>{t('PROTOCOL.B.EQUIPMENT')}</Col>
            <Col span={8}>{t('PROTOCOL.B.CONDITION')}</Col>
            <Col span={8}>{t('PROTOCOL.B.QUANTITY')}</Col>
          </Row>
        </div>
        {equipmentList.map((equipment, index) => (
          <Row gutter={[16, 16]} key={index}>
            <Col span={8}>
              <div className="my-2">{getTranslatedEquipmentName(equipment.name)}</div>{' '}
              {/* Tłumaczenie nazwy wyposażenia */}
            </Col>
            <Col span={8}>
              <Form.Item>
                <Select
                  options={conditionOptions}
                  onChange={(value) => handleConditionChange(equipment.name, value as EquipmentCondition)}
                  value={equipment.condition}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item>
                <Input
                  min={0}
                  type="number"
                  value={equipment.quantity}
                  onChange={(e) => handleQuantityChange(equipment.name, parseInt(e.target.value))}
                />
              </Form.Item>
            </Col>
          </Row>
        ))}
      </Form>
    </Card>
  );
};

export default EstablishedEquipmentState;
