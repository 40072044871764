import { Button, Card, Input } from 'antd';
import * as React from 'react';
import { useState } from 'react';

import { firebaseFunctions } from '../../../firebase/firebase';
import { FirebaseCallableFunctions } from '../../../firebase/firebase.models';
import { SHOW_LOGS } from '../../App/App';
interface Props {
  offerId: string;
}

export const OfferMainButtonsCaller: React.FC<Props> = ({ offerId }: { offerId: string }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [email, setEmail] = useState<string>('');
  const [error, setError] = useState<string>('');

  const handleSubmit = async () => {
    setLoading(true);
    setEmail(email.trim());
    try {
      const resp = await firebaseFunctions.httpsCallable(FirebaseCallableFunctions.CALLER_SEND_OFFER)({
        email: email,
        offerId: offerId,
      });
      setLoading(false);
      setEmail('');
      SHOW_LOGS && console.log(resp);
      alert(resp.data.message);
    } catch (error) {
      console.log(error);
      setLoading(false);
      setError('Error while sending offer to user');
    }
  };
  return (
    <Card className="w-96 space-y-1 mt-4" title="Send offer to user">
      <Input type="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Enter email" />
      <Button onClick={handleSubmit} className="mt-8" loading={loading}>
        Send
      </Button>
      {error && <div className="text-red-500">{error}</div>}
    </Card>
  );
};
