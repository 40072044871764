import { Button, Checkbox, Modal, message } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import { AppRoutes } from '../../../features/Routing/routing.model';
import { firebaseFunctions } from '../../../firebase/firebase';
import { FirebaseCallableFunctions } from '../../../firebase/firebase.models';

interface Props {
  isModalOpen: boolean;
  toggleModal: () => void;
  offerId: string;
  uid: string;
}

export const RejectProtocolModal: React.FC<Props> = ({ isModalOpen, toggleModal, offerId, uid }) => {
  const [checkedReasons, setCheckedReasons] = useState({
    damagedPremise: false,
    mismatchedDetails: false,
    unavailabilityOfLandlord: false,
  });

  const isSubmitDisabled = !Object.values(checkedReasons).some((value: boolean) => value);
  const [isSending, setIsSending] = useState(false);
  const { t } = useTranslation();
  const history = useHistory();

  const onRejectProtocol = async () => {
    setIsSending(true);
    const rejectProtocol = firebaseFunctions.httpsCallable(FirebaseCallableFunctions.ON_REJECT_PROTOCOL);
    await rejectProtocol({ offerId, uid, ...checkedReasons });
    message.success(t('REJECT_LOCAL.SUBMISSION_ACCEPTED'));
    setIsSending(false);
    toggleModal();
    history.push(AppRoutes.MOJA_TABLICA_SIGNED_OFFERS_TENANT);
  };

  const handleCheckboxChange = (reason: keyof typeof checkedReasons) => {
    setCheckedReasons((prevState) => ({ ...prevState, [reason]: !prevState[reason] }));
  };

  return (
    <Modal
      title={t('REJECT_LOCAL.TITLE')}
      open={isModalOpen}
      onOk={onRejectProtocol}
      onCancel={toggleModal}
      cancelButtonProps={{ style: { display: 'none' } }}
      okButtonProps={{ style: { display: 'none' } }}
      footer={
        <Button type="primary" onClick={onRejectProtocol} disabled={isSubmitDisabled} loading={isSending}>
          {t('CONFIRM')}
        </Button>
      }>
      <div className="my-4">
        <Checkbox checked={checkedReasons.damagedPremise} onChange={() => handleCheckboxChange('damagedPremise')}>
          {t('REJECT_LOCAL.REASON_DAMAGED_PREMISE')}
        </Checkbox>
      </div>

      <div className="my-4">
        <Checkbox checked={checkedReasons.mismatchedDetails} onChange={() => handleCheckboxChange('mismatchedDetails')}>
          {t('REJECT_LOCAL.REASON_MISMATCHED_DETAILS')}
        </Checkbox>
      </div>

      <div className="my-4">
        <Checkbox
          checked={checkedReasons.unavailabilityOfLandlord}
          onChange={() => handleCheckboxChange('unavailabilityOfLandlord')}>
          {t('REJECT_LOCAL.REASON_UNAVAILABILITY_OF_LANDLORD')}
        </Checkbox>
      </div>
    </Modal>
  );
};
