import { Card, Space, Typography } from 'antd';
import React from 'react';

import { AdminApp } from './AdminApp';
import { AddAdmin } from './components/AddAdmin';
import { AddCreator } from './components/AddCreator';

const { Title, Paragraph } = Typography;

export const AdminHome: React.FC = () => {
  return (
    <AdminApp>
      <div className="display-grid gap-12 min-h-screen">
        <Card className="max-w-2xl mx-auto">
          <Space direction="vertical" size="large" style={{ width: '100%' }}>
            <Title level={4}>Welcome to the Admin Home Page</Title>
            <Paragraph>This is the admin dashboard where you can manage the application.</Paragraph>
            <AddAdmin />
            <AddCreator />
          </Space>
        </Card>
      </div>
    </AdminApp>
  );
};
