// ConfirmIdentity.tsx
import { Space } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import { ToastVariant } from '../../../../../components/Toast/toast.model';
import { toastService } from '../../../../../components/Toast/toast.service';
import { firestore } from '../../../../../firebase/firebase';
import { FirestoreCollection } from '../../../../../firebase/firebase.models';
import { useGetIdentityVerificationStatus } from '../../../../../firebase/hooks/useGetIdentityVerificationStatus.hook';
import { Verification } from '../../../../../models/offers.model';
import { gtm } from '../../../../../reportGTM';
import { App, SHOW_LOGS } from '../../../../App/App';
import { useAuth } from '../../../../Auth/AuthContext';
import { useDocumentModal } from '../../../../Documents/DocumentModalProvider';
import { AppRoutes } from '../../../../Routing/routing.model';
import { MenuWrapper } from '../../../components/MenuWrapper';
import { additionalNavigation } from '../../../Menu/AdditionalNavigation';
import { MenuItems } from '../../../Menu/Menu';

import { AlertMessage } from './AlertMessage';
import { FileUploadComponent } from './FileUploadComponent';
import { getDownloadUrl, notifyAdmin, uploadFile } from './firebaseIdentityService';

const FILE_NAME = 'Regulamin';

export const ConfirmIdentity: React.FC = () => {
  const { push } = useHistory();
  const { currentUser, sendVerificationEmail } = useAuth();

  const [fileList, setFileList] = useState([]);
  const [isUploading, setIsUploading] = useState(false);
  const [showEmailAlert, setShowEmailAlert] = useState<boolean>(false);
  const [uploaded, setUploaded] = useState(false);
  const [signedTerms, setSignedTerms] = useState('');
  const { showDocumentModal } = useDocumentModal();
  const [identityVerificationStatus] = useGetIdentityVerificationStatus(currentUser?.uid);
  const { t } = useTranslation();

  const onClick = (e) => {
    additionalNavigation(e.key);
    push(e.key);
  };

  useEffect(() => {
    if (!isUploading) {
      fetchData();
    }
  }, [isUploading]);

  useEffect(() => {
    if (currentUser && !currentUser.emailVerified) {
      setShowEmailAlert(true);
    }
  }, [currentUser]);

  const fetchData = async () => {
    try {
      await loadFile();
    } catch (error) {
      console.error('Error fetching data: ', error);
    }
  };

  const loadFile = async () => {
    try {
      const downloadUrl = await getDownloadUrl(currentUser.uid, FILE_NAME);
      setFileList([{ uid: '0', name: FILE_NAME, status: 'done', url: downloadUrl }]);
      setSignedTerms(downloadUrl);
    } catch (error) {
      SHOW_LOGS && console.error(error);
      setFileList([]);
    }
  };

  const customUpload = async (options) => {
    const { file, onError, onSuccess } = options;
    setIsUploading(true);

    try {
      const downloadUrl = await uploadFile(file, `/users/${currentUser.uid}/Regulamin`);
      setSignedTerms(downloadUrl);
      onSuccess({ url: downloadUrl }, file);

      const snapshot = await firestore.collection(FirestoreCollection.USERS).doc(currentUser.uid).get();
      const userData = snapshot.data();

      if (!userData) {
        throw new Error(t('NO_USER_DATA'));
      }

      await notifyAdmin({
        userUid: currentUser.uid,
        fileUrl: downloadUrl,
        firstName: userData.firstName,
        lastName: userData.lastName,
      });

      toastService.show(t('VERIFICATION_IDENTITY_TOAST'), t('SUCCESS'));
      setUploaded(true);
      gtm('form_submit_reg_step_4', { state: 'RegulationsSignedSubmitted' });
    } catch (error) {
      onError(error);
      toastService.show(t('ERROR_DESCRIPTION'), t('ERROR'), { variant: ToastVariant.ERROR });
    } finally {
      setIsUploading(false);
    }
  };

  return (
    <App>
      <div className="flex">
        <MenuWrapper>
          <MenuItems
            onClick={onClick}
            defaultSelectedKeys={[AppRoutes.MOJA_TABLICA_CONFIRM_IDENTITY]}
            defaultOpenKeys={['sub2', 'sub3']}
          />
        </MenuWrapper>
        <Space direction="vertical" style={{ margin: 50 }}>
          <AlertMessage
            showEmailAlert={showEmailAlert}
            identity={identityVerificationStatus}
            sendVerificationEmail={sendVerificationEmail}
            t={t}
            toastService={toastService}
          />

          <FileUploadComponent
            show={
              !uploaded &&
              identityVerificationStatus !== Verification.ACCEPTED &&
              identityVerificationStatus !== Verification.CHECKING
            }
            isUploading={isUploading}
            fileList={fileList}
            customUpload={customUpload}
          />

          {signedTerms &&
          (identityVerificationStatus == Verification.ACCEPTED ||
            identityVerificationStatus == Verification.CHECKING) ? (
            <a onClick={() => showDocumentModal(signedTerms)} rel="noreferrer" className="text-blue-500">
              {t('VIEW_SIGNED_TERMS')}
            </a>
          ) : null}
        </Space>
      </div>
    </App>
  );
};
