import { firestore } from '../../firebase/firebase';
import { FirestoreCollection } from '../../firebase/firebase.models';
import { useSequentialId } from '../useSequentialId.hook';

export const onLogOfferAddConfirm = (offerId: string): void => {
  const { generateSequentialId } = useSequentialId();

  generateSequentialId().then((newCounter) => {
    firestore
      .collection(FirestoreCollection.EVENTS)
      .doc(newCounter.toString())
      .set({
        method: 'onLogOfferAddConfirm',
        details: 'User confirmed adding a new offer',
        offerId: offerId,
        date: new Date(),
      })
      .catch((error) => {
        console.log(error);
      });
  });
};
