import { useEffect, useState } from 'react';

import { firestore } from '../../../firebase/firebase';
import { FirestoreCollection } from '../../../firebase/firebase.models';
import { ReceptionForm } from '../../../models/offers.model';

interface ReceptionTime {
  date: Date;
  hour: Date;
}

export const useInitialReceptionFormValues = (
  id: string,
  landlord?: boolean
): { initialValues: ReceptionForm; isLoading: boolean; receptionTime?: ReceptionTime | null } => {
  const [initialValues, setInitialValues] = useState<ReceptionForm | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [receptionTime, setReceptionTime] = useState<ReceptionTime | null>(null);

  useEffect(() => {
    const fetchInitialValues = async () => {
      try {
        const doc = await firestore.collection(FirestoreCollection.OFFERS).doc(id).get();
        if (doc.exists) {
          const data = doc.data();
          if (landlord) {
            setInitialValues(data?.transferOfThePremise || null);
          } else {
            setInitialValues(data?.receptionOfThePremise || null);
            try {
              if (data?.receptionTime.date && data?.receptionTime.hour) {
                setReceptionTime({ date: data?.receptionTime.date || null, hour: data?.receptionTime.hour || null });
              }
            } catch (error) {
              console.error('Failed to convert date and time', error);
            }
          }
        }
      } catch (error) {
        console.error('Error fetching initial values:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchInitialValues();
  }, [id, landlord]);

  return { initialValues, isLoading, receptionTime };
};
