import type { MenuProps } from 'antd';
import React from 'react';
import { useHistory } from 'react-router';

import { App } from '../../../App/App';
import { useAuth } from '../../../Auth/AuthContext';
import { AppRoutes } from '../../../Routing/routing.model';
import { MenuWrapper } from '../../components/MenuWrapper';
import { additionalNavigation } from '../../Menu/AdditionalNavigation';
import { MenuItems } from '../../Menu/Menu';

import { FromReport } from './FormReport';

export const Report: React.FC = () => {
  const { push } = useHistory();
  const { currentUser } = useAuth();
  const onClick: MenuProps['onClick'] = (e) => {
    additionalNavigation(e.key);
    push(e.key);
  };

  return (
    <App>
      <MenuWrapper>
        <MenuItems onClick={onClick} defaultSelectedKeys={[AppRoutes.MOJA_TABLICA_REPORT]} defaultOpenKeys={['sub4']} />
      </MenuWrapper>
      <FromReport userUid={currentUser.uid} />
    </App>
  );
};
