import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { SelectOption } from '../../../../models/main.model';

export function useMaterialType(): SelectOption[] {
  const { t } = useTranslation(); // Using translation hook

  return useMemo(
    () =>
      [
        { label: t('MATERIAL.BRICK'), value: 'Murowany' },
        { label: t('MATERIAL.STEEL'), value: 'Stalowy' },
        { label: t('MATERIAL.WOOD'), value: 'Drewniany' },
      ].map((item) => ({
        label: item.label,
        value: item.value,
      })),
    [t]
  );
}
