import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { getLinkByShortId, recordLinkClick } from '../Caller/services/link.service';
import { AppRoutes } from '../Routing/routing.model';

interface LinkRedirectParams {
  shortId: string;
}

export const LinkRedirect: React.FC = () => {
  const { shortId } = useParams<LinkRedirectParams>();
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const history = useHistory();

  useEffect(() => {
    const redirectToOriginalUrl = async () => {
      try {
        if (!shortId) {
          setError('Invalid link ID');
          setLoading(false);
          return;
        }

        const linkDoc = await getLinkByShortId(shortId);

        if (!linkDoc) {
          setError('Link not found');
          setLoading(false);
          return;
        }

        // Record the click (don't await to make redirect faster)
        recordLinkClick(shortId).catch(console.error);

        // Redirect to the original URL
        window.location.href = linkDoc.originalUrl;
      } catch (err) {
        console.error('Error redirecting:', err);
        setError('An error occurred while redirecting');
        setLoading(false);
      }
    };

    redirectToOriginalUrl();
  }, [shortId, history]);

  if (loading) {
    return (
      <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100">
        <div className="text-center p-8 max-w-md bg-white rounded-lg shadow-lg">
          <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500 mx-auto mb-4"></div>
          <h2 className="text-xl font-semibold mb-2">Redirecting you...</h2>
          <p className="text-gray-600">Please wait while we redirect you to your destination.</p>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100">
        <div className="text-center p-8 max-w-md bg-white rounded-lg shadow-lg">
          <h2 className="text-xl font-semibold mb-2">Link Not Found</h2>
          <p className="text-red-500 mb-4">{error}</p>
          <button
            className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition"
            onClick={() => history.push(AppRoutes.HOME)}>
            Go to Homepage
          </button>
        </div>
      </div>
    );
  }

  return null;
};
