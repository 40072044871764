import firebase from 'firebase/app';
import { nanoid } from 'nanoid';

import { firebaseAuth, firestore } from '../../../firebase/firebase';
import { LinkDocument } from '../models/link.model';

const LINKS_COLLECTION = 'links';
const SHORT_ID_LENGTH = 6;

/**
 * Generates a unique short ID for a URL
 * @returns A unique 6-character ID
 */
export const generateShortId = (): string => {
  return nanoid(SHORT_ID_LENGTH);
};

/**
 * Creates a shortened link in Firestore
 * @param originalUrl The original URL to shorten
 * @returns The created link document
 */
export const createShortenedLink = async (originalUrl: string): Promise<LinkDocument> => {
  try {
    // Generate a unique short ID
    const shortId = generateShortId();

    // Create the document data
    const newLink: LinkDocument = {
      originalUrl,
      shortId,
      createdAt: firebase.firestore.Timestamp.now(),
      createdBy: firebaseAuth.currentUser?.uid,
      clicks: 0,
    };

    // Add the document to Firestore
    const linkRef = firestore.collection(LINKS_COLLECTION).doc(shortId);
    await linkRef.set(newLink);

    return newLink;
  } catch (error) {
    console.error('Error creating shortened link:', error);
    throw new Error('Failed to create shortened link');
  }
};

/**
 * Retrieves a link document by its short ID
 * @param shortId The short ID to look up
 * @returns The link document or null if not found
 */
export const getLinkByShortId = async (shortId: string): Promise<LinkDocument | null> => {
  try {
    const linkDoc = await firestore.collection(LINKS_COLLECTION).doc(shortId).get();

    if (!linkDoc.exists) {
      return null;
    }

    return linkDoc.data() as LinkDocument;
  } catch (error) {
    console.error('Error retrieving link:', error);
    throw new Error('Failed to retrieve link');
  }
};

/**
 * Records a click on a link and updates its stats
 * @param shortId The short ID of the clicked link
 */
export const recordLinkClick = async (shortId: string): Promise<void> => {
  try {
    const linkRef = firestore.collection(LINKS_COLLECTION).doc(shortId);

    // Używamy atomowej aktualizacji bez odczytu dokumentu,
    // co jest dozwolone przez nasze reguły bezpieczeństwa nawet dla niezalogowanych użytkowników
    await linkRef.update({
      clicks: firebase.firestore.FieldValue.increment(1),
      lastClickedAt: firebase.firestore.Timestamp.now(),
    });
  } catch (error) {
    console.error('Error recording link click:', error);
    // Don't throw here, just log - we don't want to block redirect

    // Spróbujmy alternatywne podejście, jeśli się nie udało
    try {
      // Zdefiniujmy linkRef ponownie w tym bloku
      const linkRef = firestore.collection(LINKS_COLLECTION).doc(shortId);

      // Sprawdźmy, czy dokument istnieje przed aktualizacją
      const linkDoc = await linkRef.get();
      if (linkDoc.exists) {
        await linkRef.update({
          clicks: firebase.firestore.FieldValue.increment(1),
          lastClickedAt: firebase.firestore.Timestamp.now(),
        });
      }
    } catch (fallbackError) {
      console.error('Fallback error recording link click:', fallbackError);
    }
  }
};
