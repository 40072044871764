import firebase from 'firebase/app';
import { useEffect, useState } from 'react';

import { Company } from '../../features/MojaTablica/Account/Identity/LinkCompany/company.models';
import { firestore } from '../firebase';
import { FirestoreCollection } from '../firebase.models';

export function useGetCompany(uid: string | null): [Company | null, boolean, firebase.FirebaseError | null] {
  const [error, setError] = useState<firebase.FirebaseError | null>(null);
  const [loading, setLoading] = useState(false);
  const [company, setCompany] = useState<Company | null>(null);

  useEffect(() => {
    if (!uid) {
      setLoading(false);
      return;
    }

    setLoading(true);
    setError(null);
    const unsubscribe = firestore
      .collection(FirestoreCollection.USERS)
      .doc(uid)
      .onSnapshot(
        (snapshot) => {
          const data = snapshot.data();
          setCompany(data ? ({ ...data.company } as Company) : null);
          setLoading(false);
        },
        (e) => {
          console.error('Error fetching company:', e);
          setError(e);
          setLoading(false);
        }
      );
    return () => unsubscribe();
  }, [uid]);

  return [company, loading, error];
}
