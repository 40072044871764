import { Currency } from '../../../models/main.model';
import { Offer, OfferStatusState, OfferType } from '../../../models/offers.model';
import { getTerms } from '../Documents/getTerms';

export function getInitialValues(): Offer {
  const emptyOffer: Offer = {
    legalWarehouseUseAccepted: [],
    legalWarehouseUseOfficial: [],
    offerStatus: {
      state: OfferStatusState.UNSIGNED,
    },
    relatedDetails: {},
    landlord: '',
    priceCurrency: Currency.PLN,
    parkingDetails: {
      guardedParking: false,
      totalParkingSurface: null,
      parkingSurface: null,
      parkingLotsAvailable: null,
    },
    legalMainUse: '',
    id: '',
    offerType: OfferType.RENT,
    name: '',
    landAndMortgageRegisterNumber: '',
    address: {
      plotNumber: '',
      street: '',
      postCode: '',
      district: '',
      city: '',
      country: '',
      province: '',
      inputText: '',
      ownershipType: '',
      officialAddress: {
        houseNumber: '',
        street: '',
        estate: '',
        district: '',
        county: '',
        province: '',
      },
    },
    geoMarker: { lat: null, lng: null },
    availability: new Date(new Date(new Date().setDate(new Date().getDate() + 1)).setHours(23, 59, 59, 999)),
    availabilityTo: null,
    offerValidTo: new Date(new Date(new Date().setMonth(new Date().getMonth() + 1)).setHours(23, 59, 59, 999)),
    areaDetails: {
      buildingType: '',
      constructionMaterial: [],
      thermalInsulation: [],
      constructionCompletionYear: null,
      landArea: null,
      drive: [],
      surface: null,
      publicTransportDistance: null,
      totalSurface: null,
      surroundings: [],
      levels: [
        {
          height: null,
          levelName: '',
          accessibility: false,
          toiletsLocation: false,
          levelNumber: null,
          numberOfStaircases: null,
          surface: null,
          offices: [],
          technicalRooms: [],
          warehouses: [],
          commercialAndServiceRooms: [],
          otherRooms: null,
          otherRoomsDetails: [],
          lift: false,
          liftCapacityKg: null,
        },
      ],
    },
    price: null,
    mediaDetails: {
      energyCategory: '',
      airConditioning: false,
      gravityVentilation: false,
      heatRecovery: false,
      sewerage: false,
      powerAllocation: null,
      LAN: false,
      heating: false,
      gas: false,
      phoneLine: false,
      transformerIn: false,
      fiber: false,
      doublePowerLines: false,
      water: false,
    },
    description: '',
    leaseAgreement: {
      infinite: false,
      fixedshort: false,
      fixedlong: false,
      deposit: null,
      payDay: null,
      nonRentFees: '',
      firstPaymentDay: null,
    },
    summary: { summary1: false, summary2: false, summary3: false, summary4: false },
    uid: '',
    unitReceiptDeadline: null,
    ownTerms: {
      selected: false,
      terms: getTerms(),
    },
    counteroffer: {
      selected: false,
      isRentToggleChecked: false,
      isContractDurationToggleChecked: false,
      newPrice: null,
      newDuration: null,
      tenant: '',
    },
    subleaseAgreement: false,
  };

  return emptyOffer;
}
