import { useEffect, useState } from 'react';

import { firestore } from '../../../firebase/firebase';
import { FirestoreCollection } from '../../../firebase/firebase.models';
import { CallerData, UserEntry } from '../../Admin/models/statistics.model';

export const useGetCallersStatistics = (): CallerData[] => {
  const [data, setData] = useState<CallerData[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const callersSnapshot = await firestore.collection(FirestoreCollection.CALLERS).get();

        // Poprawione typowanie CallerData
        const callersData: CallerData[] = await Promise.all(
          callersSnapshot.docs.map(async (callerDoc) => {
            const callerId = callerDoc.id;

            // Pobieramy imię i nazwisko callera
            const callerName = await getCallerName(callerId);

            // Pobieramy dane dla newUsers i existingUsers
            const fetchUserType = async (userType: string) => {
              const userTypeSnapshot = await callerDoc.ref.collection(userType).get();
              const userTypeData: Record<string, UserEntry> = {};

              for (const offerDoc of userTypeSnapshot.docs) {
                const offerData = offerDoc.data() as UserEntry;

                // Pobieramy nazwę oferty
                offerData.campaignData.utmSource = await getOfferName(offerData.campaignData.utmSource);

                userTypeData[offerDoc.id] = offerData;
              }

              return userTypeData;
            };

            const [newUsers, existingUsers] = await Promise.all([
              fetchUserType('newUsers'),
              fetchUserType('existingUsers'),
            ]);

            return { caller: callerName, newUsers, existingUsers };
          })
        );

        setData(callersData);
      } catch (error) {
        console.error('Error fetching caller statistics:', error);
      }
    };

    fetchData();
  }, []);

  return data;
};

// Pobranie imienia i nazwiska dzwoniącego
const getCallerName = async (callerId: string) => {
  const callerDoc = await firestore.collection(FirestoreCollection.USERS).doc(callerId).get();
  const callerData = callerDoc.data();

  return callerData ? `${callerData.firstName} ${callerData.lastName}` : 'Unknown';
};

// Pobranie nazwy oferty
const getOfferName = async (offerId: string) => {
  const offerDoc = await firestore.collection(FirestoreCollection.OFFERS).doc(offerId).get();
  const offerData = offerDoc.data();

  return offerData?.name || 'Unknown';
};
