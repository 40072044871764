import { Alert, FormProps, message, Modal, Space, Spin } from 'antd';
import firebase from 'firebase/app';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { Loader } from 'rsuite';

import { useGetOffer } from '../../firebase/hooks/getOfferById.hook';
import { getTotalIncome } from '../../firebase/hooks/getTotalIncome.hook';
import { useInvoiceNumber } from '../../hooks/useInvoiceNumber.hook';
import { Offer, PostSigningStatus } from '../../models/offers.model';
import { ProtocolData } from '../../models/protocol.model';
import { gtm } from '../../reportGTM';
import { App } from '../App/App';
import { useAuth } from '../Auth/AuthContext';
import { fetchUserData } from '../MojaTablica/Account/Identity/ConfirmIdentity/firebaseIdentityService';
import { AppRoutes } from '../Routing/routing.model';

import { ProtocolForm } from './ProtocolForm';
import { generateApprovalUrl } from './generateApprovalUrl';
import { handleOnProtocolSign } from './handleOnProtocolSign.hook';
import { handleOnProtocolSubmit } from './handleOnProtocolSubmit.hook';

interface Props {
  id: string;
}

interface PropsWhenAuth {
  offerId: string;
  currentUser: firebase.User;
}

export const Protocol: React.FC<Props> = ({ id }) => {
  const { isAuthenticating, isAuth, currentUser } = useAuth();

  return (
    <App>
      {!isAuthenticating && isAuth && <WhenAuth offerId={id} currentUser={currentUser} />}
      {isAuthenticating && !isAuth && <WhenNotAuth />}
    </App>
  );
};

const WhenAuth: React.FC<PropsWhenAuth> = ({ offerId, currentUser }) => {
  const { t } = useTranslation(); // Use hooks at the top level of the component
  const [loading, setLoading] = useState<boolean>(false);
  const [offer, isLoading, error] = useGetOffer(offerId);
  const history = useHistory();
  const [validationError, setValidationError] = useState<React.ReactElement | null>(null);
  const [landlordData, setLandlordData] = useState(null);
  const { invoiceNumbers } = useInvoiceNumber();

  const landlordInvoiceNumber = invoiceNumbers?.landlord ? `L${invoiceNumbers.landlord}` : null;
  const tenantInvoiceNumber = invoiceNumbers?.tenant ? `T${invoiceNumbers.tenant}` : null;

  useEffect(() => {
    const fetchData = async () => {
      if (offer?.uid) {
        try {
          const data = await fetchUserData(offer.uid);
          setLandlordData(data);
        } catch (error) {
          console.error('Error fetching landlord data:', error);
        }
      }
    };

    if (offer) {
      fetchData();
    }
  }, [offer]);

  useEffect(() => {
    if (offer) {
      const error = validateOfferData(offer, currentUser, t);
      if (error) {
        setValidationError(error);
        console.log('error!');
      }
    }
  }, [offer, currentUser, t]);

  useEffect(() => {
    if (validationError) {
      Modal.error({
        title: t('PROTOCOL.ERROR_FORM_NOT_FILLED'),
        content: validationError,
        onOk: () => history.push(AppRoutes.MOJA_TABLICA_SIGNED_OFFERS_LANDLORD),
      });
    }
  }, [validationError, history, t]);

  const isActive = offer?.offerStatus && !(offer.offerStatus.postSigningStatus === PostSigningStatus.DEPOSIT_PENDING);
  const isSigned =
    offer?.offerStatus &&
    (offer.offerStatus.postSigningStatus === PostSigningStatus.PROTOCOL_CONFIRMED ||
      offer.offerStatus.postSigningStatus === PostSigningStatus.PROTOCOL_ATTACHED);

  const onSubmitProtocol: FormProps<ProtocolData>['onFinish'] = async (values) => {
    setLoading(true);
    Modal.info({
      title: (
        <p>
          {t('PROTOCOL.LOADING_MESSAGE')}
          <Spin className="ml-2" />
        </p>
      ),
      content: (
        <div>
          <p>
            {t('PROTOCOL.NEXT_STEP_MESSAGE', {
              role: currentUser.uid === offer.uid ? t('PROTOCOL.ROLE_TENANT') : t('PROTOCOL.ROLE_LANDLORD'),
            })}
          </p>
        </div>
      ),
      okButtonProps: { style: { display: 'none' } },
    });
    const { success, description } = await handleOnProtocolSubmit({ offerId, values, currentUser });
    if (!success) message.error(t('PROTOCOL.' + description));
    setLoading(false);
    history.push(AppRoutes.MOJA_TABLICA_SIGNED_OFFERS_LANDLORD);
    setTimeout(() => {
      Modal.destroyAll();
    }, 1500);
  };

  const handleApprovalClick = async (accepted: boolean) => {
    const transactionId = `${landlordInvoiceNumber}-${tenantInvoiceNumber}-${new Date().getFullYear()}`;

    try {
      Modal.info({
        title: (
          <p>
            {t('PROTOCOL.LOADING_MESSAGE')}
            <Spin className="ml-2" />
          </p>
        ),
        content: <p>{t('PROTOCOL.CONFIRMATION_EMAIL')}</p>,
        okButtonProps: { style: { display: 'none' } },
      });

      if (accepted) {
        const { success, description } = await handleOnProtocolSign({ offerId, accepted, currentUser });
        const totalIncome = await getTotalIncome(offer.id);
        const price = typeof offer.price === 'string' ? parseFloat(offer.price) : offer.price;
        const formattedPrice = price && !isNaN(price) ? price.toFixed(2) : '0.00'; // Formatowanie na 2 miejsca po przecinku
        gtm('purchase', {
          currency: 'PLN',
          value: totalIncome,
          transaction_id: transactionId,
          items: [
            {
              item_id: offer.id,
              item_name: offer.name,
              price: parseFloat(formattedPrice),
              affiliation: landlordData?.company?.nazwa || '',
              item_category: offer.legalMainUse,
              location_id: offer.placeId,
            },
          ],
        });
        if (!success) {
          message.error(t('PROTOCOL.' + description)); // Showing the message based on the returned description
        }
      }

      const url = await generateApprovalUrl(accepted, offerId, currentUser);
      window.location.assign(url);
    } catch (error) {
      message.error(t('PROTOCOL.REJECT_ERROR'));
      console.error('Error during approval:', error);
    }
  };

  if (validationError) {
    return <></>;
  }

  if (error) {
    return (
      <Space direction="vertical" style={{ width: '100%', marginTop: '100px', marginBottom: '60%', padding: '50px' }}>
        <Alert showIcon message={t('PROTOCOL.ACCESS_DENIED')} description={error} type="error" />
      </Space>
    );
  } else if (offer === undefined || isLoading) {
    return (
      <Loader
        style={{ position: 'absolute', top: '108%', left: '50%', transform: 'translate(-50%, -50%)' }}
        size="md"
        center
        content="loading..."
      />
    );
  } else {
    return (
      <>
        <Modal
          open={!isActive}
          closable={false}
          footer={null}
          maskClosable={false}
          title={t('PROTOCOL.UNAVAILABLE_FORM')}>
          <div>
            <p>{t('PROTOCOL.BLOCKED_MESSAGE')}</p>
          </div>
        </Modal>
        {offer.leaseAgreement.fixedlong && (
          <Alert className="m-4" type="info" showIcon message={t('PROTOCOL.SIGN_PERSONALLY_INFO')} />
        )}
        <ProtocolForm
          offerId={offerId}
          o={offer}
          onFinish={onSubmitProtocol}
          isAddingPending={loading}
          active={isActive && !isSigned}
          handleApprovalClick={handleApprovalClick}
        />
      </>
    );
  }
};

// Ensure that validateOfferData does not call hooks inside it.
const validateOfferData = (
  o: Offer,
  currentUser: firebase.User,
  t: (key: string) => string
): React.ReactElement | null => {
  const endLine = <div className="font-medium mt-2">{t('PROTOCOL.SIGN_PERSONALLY_INFO')}</div>;
  if (!o.transferOfThePremise) {
    return currentUser.uid === o.uid ? (
      <>
        {t('PROTOCOL.ERROR_FORM_NOT_FILLED')}
        {endLine}
      </>
    ) : (
      <>
        {t('PROTOCOL.ERROR_LANDLORD_FORM')}
        {endLine}
      </>
    );
  }

  if (!o.receptionTime) {
    return currentUser.uid !== o.uid ? (
      <>
        {t('PROTOCOL.ERROR_RECEPTION_NOT_FILLED')}
        {endLine}
      </>
    ) : (
      <>
        {t('PROTOCOL.ERROR_TENANT_RECEPTION')}
        {endLine}
      </>
    );
  }

  return null;
};

const WhenNotAuth: React.FC = () => {
  const { showSignInModal, closeModal } = useAuth();

  useEffect(() => {
    showSignInModal();
    return () => closeModal();
  }, []);

  return <></>;
};
