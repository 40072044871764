import { Button, Layout, Typography } from 'antd';
import React, { useContext } from 'react';

import { CallerContext } from '../CallerContext';
const { Header, Content, Footer } = Layout;
const { Title } = Typography;

export const CallerApp: React.FC = ({ children }: { children?: React.ReactNode }) => {
  const { openWebsiteModal, openLinkModal } = useContext(CallerContext);

  return (
    <div className="bg-gray-100">
      <Header
        style={{
          backgroundColor: '#001529',
          padding: '10px 20px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}>
        <Title level={3} style={{ color: '#fff', margin: 0 }}>
          Dashboard
        </Title>
        <div>
          <Button type="primary" onClick={openWebsiteModal} style={{ marginRight: '10px' }}>
            Send Website Link
          </Button>
          <Button onClick={openLinkModal}>Link Options</Button>
        </div>
      </Header>
      <Content style={{ padding: '50px' }}>
        <div className="mx-auto">{children}</div>
      </Content>
      <Footer style={{ textAlign: 'center' }}>© {new Date().getFullYear()} najmuje.com</Footer>
    </div>
  );
};
