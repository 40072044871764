import firebase from 'firebase/app';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';

import { firestore } from '../../../firebase/firebase';
import { FirestoreCollection } from '../../../firebase/firebase.models';
import { UserVerified } from '../../../models/main.model';
import { Verification } from '../../../models/offers.model';
import { AppRoutes } from '../../Routing/routing.model';

export const isUserVerified = (currentUser: firebase.User): UserVerified | null => {
  const [userVerified, setUserVerified] = useState<UserVerified>({
    verified: false,
    message: 'Błąd',
    description: null,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    action: () => {},
    routeName: null,
  });
  const { push } = useHistory();

  useEffect(() => {
    if (!currentUser) return;

    firestore
      .collection(FirestoreCollection.USERS)
      .doc(currentUser.uid)
      .get()
      .then((doc) => {
        if (!currentUser.emailVerified) {
          setUserVerified({
            verified: false,
            message: 'Potwierdź adres email',
            description: 'Nie możesz podpisać umowy gdy nie masz potwierdzonego adresu email',
            action: () => {
              push(AppRoutes.MOJA_TABLICA_ACCOUNT_INFO);
            },
            routeName: 'Potwierdź email',
          });
        } else if (!doc.data()?.verification?.identity || doc.data().verification.identity != Verification.ACCEPTED) {
          setUserVerified({
            verified: false,
            message: 'Potwierdź swoją tożsamość',
            description: 'Nie możesz podpisać umowy gdy nie masz potwierdzonej tożsamości',
            action: () => push(AppRoutes.MOJA_TABLICA_CONFIRM_IDENTITY),
            routeName: 'Potwierdź',
          });
        } else if (!doc.data()?.verification?.company || doc.data().verification.company != Verification.ACCEPTED) {
          setUserVerified({
            verified: false,
            message: 'Aby podpisać umowę musisz mieć konto połączone z firmą',
            description: 'Nie możesz podpisać umowy najmu jako osoba fizyczna.',
            action: () => push(AppRoutes.MOJA_TABLICA_LINK_COMPANY),
            routeName: 'Połącz z firmą',
          });
        } else {
          setUserVerified((prevState) => ({ ...prevState, verified: true }));
        }
      });
  }, [currentUser]);

  return userVerified;
};
