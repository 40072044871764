import * as React from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Button } from 'rsuite';

import { CompanyModal } from '../../../components/Alerts/CompanyModal';
import { IdentityModal } from '../../../components/Alerts/IdentityModal';
import { Offer } from '../../../models/offers.model';
import { gtm } from '../../../reportGTM';
import { handleOnOfferAdd } from '../../OfferAdd/hooks/handleOnOfferAdd.hook';
import { ImageVerificationModal } from '../../OfferAdd/ImageVerificationModal';
import { AppRoutes } from '../../Routing/routing.model';

interface Props {
  offer: Offer;
  currentUser: any;
  loading: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

export const SignButton: React.FC<Props> = ({ offer, currentUser, loading, setLoading }) => {
  const { t } = useTranslation();
  const [isIdentityModalOpen, setIsIdentityModalOpen] = useState(false);
  const [isCompanyModalOpen, setIsCompanyModalOpen] = useState(false);
  const { push } = useHistory();
  const [imagesVerificationResults, setImagesVerificationResults] = useState<string | null>(null);
  const [showVerificationModal, setShowVerificationModal] = useState(false);

  const handleSubmit = () => {
    handleOnOfferAdd(
      currentUser,
      offer,
      setIsIdentityModalOpen,
      setIsCompanyModalOpen,
      setLoading,
      setImagesVerificationResults,
      push
    );
    gtm('submit_offer_project', { origin: 'preview' });
    if (imagesVerificationResults) {
      setShowVerificationModal(true);
    }
  };

  const handleCloseModal = () => setShowVerificationModal(false);

  return (
    <>
      <Button
        appearance="primary"
        loading={loading}
        onClick={handleSubmit}
        style={{ maxHeight: '40px' }}
        disabled={loading}>
        {t('FORMS.OFFER_ADD.BUTTONS.SEND_OFFER_PROJECT_WITH_LEASE_TERMS')}
      </Button>
      <IdentityModal isIdentityModalOpen={isIdentityModalOpen} />
      <CompanyModal isCompanyModalOpen={isCompanyModalOpen} />
      <ImageVerificationModal
        show={showVerificationModal}
        onClose={handleCloseModal}
        verificationResults={imagesVerificationResults}
        onChangeImage={() => push(AppRoutes.ADD_OFFER.replace(':id', offer.id))}
      />
    </>
  );
};
