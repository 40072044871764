import { Modal } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import { AppRoutes } from '../../features/Routing/routing.model';

interface Props {
  isCompanyModalOpen: boolean;
}

export const CompanyModal: React.FC<Props> = ({ isCompanyModalOpen }) => {
  const { push } = useHistory();
  const { t } = useTranslation();
  const handleCompanyOk = () => {
    push(AppRoutes.MOJA_TABLICA_LINK_COMPANY);
  };

  return (
    <Modal
      title={t('OFFER_ADD_CONFIRM_COMPANY')}
      open={isCompanyModalOpen}
      onOk={handleCompanyOk}
      cancelButtonProps={{ style: { display: 'none' } }}
      closable={false}>
      <p>{t('OFFER_ADD_CONFIRM_COMPANY_DESC')}</p>
    </Modal>
  );
};
