import { Button, Card, Divider, List, Typography } from 'antd';
import React from 'react';
import { useHistory } from 'react-router-dom';

import { AppRoutes } from '../../Routing/routing.model';

const { Title, Paragraph } = Typography;

interface PromotionRegulationProps {
  onClose?: () => void;
}

export const PromotionRegulation: React.FC<PromotionRegulationProps> = ({ onClose }) => {
  const history = useHistory();

  const handleAddOfferClick = () => {
    history.push(AppRoutes.MOJA_TABLICA_UNSIGNED_OFFERS);
    if (onClose) onClose();
  };

  return (
    <div className="promotion-regulation p-4">
      <Title level={2} className="text-center mb-6">
        Regulamin Promocji &quot;Bezpieczne 100&quot;
      </Title>
      <Paragraph className="text-lg text-center mb-6">
        Program bezpłatnego ubezpieczenia dla pierwszych 100 ofert
      </Paragraph>

      <Divider orientation="left">§1. Postanowienia ogólne</Divider>
      <List
        dataSource={[
          'Niniejszy regulamin (dalej „Regulamin") określa zasady promocji pod nazwą "Bezpieczne 100" (dalej „Promocja").',
          'Organizatorem Promocji jest Fast Letting z siedzibą w Polsce (dalej „Organizator").',
          'Promocja jest prowadzona na terytorium Rzeczypospolitej Polskiej za pośrednictwem platformy internetowej Fast Letting dostępnej pod adresem https://fastletting.com (dalej „Platforma").',
          'Promocja trwa od 10.03.2025 r. do wyczerpania puli 100 ofert objętych Promocją, nie dłużej jednak niż do 31.05.2025 r. (dalej „Okres Promocji").',
        ]}
        renderItem={(item) => <List.Item>{item}</List.Item>}
      />

      <Divider orientation="left">§2. Warunki uczestnictwa w Promocji</Divider>
      <Paragraph>
        Uczestnikiem Promocji może być osoba fizyczna, osoba prawna lub jednostka organizacyjna nieposiadająca
        osobowości prawnej, która:
      </Paragraph>
      <List
        dataSource={[
          'posiada pełną zdolność do czynności prawnych;',
          'jest zarejestrowanym użytkownikiem Platformy;',
          'w Okresie Promocji doda na Platformie ofertę najmu nieruchomości zgodnie z regulaminem Platformy;',
          'zaakceptuje niniejszy Regulamin.',
        ]}
        renderItem={(item) => <List.Item>{item}</List.Item>}
      />
      <Paragraph>
        Warunkiem skorzystania z Promocji jest dodanie kompletnej i zatwierdzonej przez Organizatora oferty najmu
        nieruchomości na Platformie w Okresie Promocji.
      </Paragraph>
      <Paragraph>
        Promocją objętych jest pierwszych 100 ofert najmu nieruchomości dodanych i zatwierdzonych na Platformie w
        Okresie Promocji.
      </Paragraph>

      <Divider orientation="left">§3. Przedmiot i zasady Promocji</Divider>
      <Paragraph>
        Przedmiotem Promocji jest przyznanie uczestnikowi Promocji bezpłatnego ubezpieczenia nieruchomości będącej
        przedmiotem oferty najmu dodanej na Platformie (dalej &quot;Ubezpieczenie&quot;).
      </Paragraph>
      <Paragraph>Ubezpieczenie obejmuje:</Paragraph>
      <List
        dataSource={[
          'ochronę przed niepłaceniem czynszu przez najemcę;',
          'zabezpieczenie przed zniszczeniem mienia;',
          'pomoc prawną w przypadku problemów z najmem;',
          'szybkie wypłaty odszkodowań w przypadku szkód.',
        ]}
        renderItem={(item) => <List.Item>{item}</List.Item>}
      />
      <Paragraph>
        Ubezpieczenie jest udzielane na okres 12 miesięcy od daty aktywacji oferty najmu na Platformie.
      </Paragraph>
      <Paragraph>
        Szczegółowe warunki Ubezpieczenia określa odrębna umowa ubezpieczenia, która zostanie przekazana uczestnikowi
        Promocji po spełnieniu warunków uczestnictwa w Promocji.
      </Paragraph>
      <Paragraph>
        Organizator poinformuje uczestnika Promocji o objęciu jego oferty Promocją za pośrednictwem wiadomości e-mail
        wysłanej na adres podany podczas rejestracji na Platformie.
      </Paragraph>

      <Divider orientation="left">§4. Reklamacje</Divider>
      <List
        dataSource={[
          'Reklamacje dotyczące Promocji można składać w formie elektronicznej na adres e-mail: kontakt@fastletting.com lub pisemnie na adres siedziby Organizatora.',
          'Reklamacja powinna zawierać co najmniej: imię i nazwisko lub nazwę uczestnika Promocji, adres e-mail, opis zdarzenia będącego podstawą reklamacji oraz żądanie.',
          'Organizator rozpatrzy reklamację w terminie 14 dni od daty jej otrzymania.',
          'Odpowiedź na reklamację zostanie wysłana na adres e-mail podany przez uczestnika Promocji w reklamacji lub na adres korespondencyjny w przypadku reklamacji złożonej w formie pisemnej.',
        ]}
        renderItem={(item) => <List.Item>{item}</List.Item>}
      />

      <Divider orientation="left">§5. Postanowienia końcowe</Divider>
      <List
        dataSource={[
          'Regulamin Promocji jest dostępny na stronie internetowej Platformy.',
          'Organizator zastrzega sobie prawo do zmiany Regulaminu z ważnych przyczyn.',
          'Organizator poinformuje o zmianie Regulaminu poprzez zamieszczenie informacji na stronie Platformy. Zmieniony Regulamin wejdzie w życie z dniem jego opublikowania.',
          'W kwestiach nieuregulowanych w Regulaminie zastosowanie mają przepisy prawa polskiego, w szczególności Kodeksu cywilnego oraz regulamin Platformy.',
          'Promocja nie łączy się z innymi promocjami organizowanymi przez Organizatora, chyba że warunki innych promocji stanowią inaczej.',
        ]}
        renderItem={(item) => <List.Item>{item}</List.Item>}
      />

      <Card className="mt-8 mb-4 bg-blue-50">
        <div className="text-center">
          <Title level={4}>Skorzystaj z promocji już teraz!</Title>
          <Paragraph className="text-lg">Dodaj swoją ofertę i otrzymaj darmowe ubezpieczenie.</Paragraph>
          <Button type="primary" size="large" onClick={handleAddOfferClick} className="mt-2">
            Dodaj ofertę i skorzystaj z promocji
          </Button>
        </div>
      </Card>
    </div>
  );
};

export default PromotionRegulation;
