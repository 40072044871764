import { Modal } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import { AppRoutes } from '../../features/Routing/routing.model';

interface Props {
  isIdentityModalOpen: boolean;
}

export const IdentityModal: React.FC<Props> = ({ isIdentityModalOpen }) => {
  const { push } = useHistory();
  const { t } = useTranslation();
  const handleIdentityOk = () => {
    push(AppRoutes.MOJA_TABLICA_CONFIRM_IDENTITY);
  };
  return (
    <Modal
      title={t('OFFER_ADD_CONFIRM_IDENTITY')}
      open={isIdentityModalOpen}
      onOk={handleIdentityOk}
      cancelButtonProps={{ style: { display: 'none' } }}
      closable={false}>
      <p>{t('OFFER_ADD_CONFIRM_IDENTITY_DESC')}</p>
    </Modal>
  );
};
