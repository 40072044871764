export type DataType = {
  availability?: { seconds: number };
  availabilityTo?: { seconds: number };
  offerValidTo?: { seconds: number };
};

export type ReturnDataType = {
  availability: Date | null;
  availabilityTo: Date | null;
  offerValidTo: Date | null;
};

export const useProcessDates = (data: DataType): ReturnDataType => {
  return {
    availability: data.availability ? new Date(data.availability.seconds * 1000) : null,
    availabilityTo: data.availabilityTo ? new Date(data.availabilityTo.seconds * 1000) : null,
    offerValidTo: data.offerValidTo ? new Date(data.offerValidTo.seconds * 1000) : null,
  };
};
