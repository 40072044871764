import { LoadingOutlined } from '@ant-design/icons';
import { Steps } from 'antd';
import * as React from 'react';
import { useState } from 'react';
import { useHistory } from 'react-router';

import { CompanyModal } from '../../../components/Alerts/CompanyModal';
import { IdentityModal } from '../../../components/Alerts/IdentityModal';
import { Offer } from '../../../models/offers.model';
import { handleOnOfferAdd } from '../../OfferAdd/hooks/handleOnOfferAdd.hook';
import { ImageVerificationModal } from '../../OfferAdd/ImageVerificationModal';
import { AppRoutes } from '../../Routing/routing.model';

interface Props {
  offer: Offer;
  currentUser: any;
  loading: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ProgessSteps: React.FC<Props> = ({ offer, currentUser, loading, setLoading }) => {
  const [current, setCurrent] = useState(1);
  const { push } = useHistory();
  const [isIdentityModalOpen, setIsIdentityModalOpen] = useState(false);
  const [isCompanyModalOpen, setIsCompanyModalOpen] = useState(false);
  const [imagesVerificationResults, setImagesVerificationResults] = useState<string | null>(null);
  const [showVerificationModal, setShowVerificationModal] = useState(false);

  const onChange = (value: number) => {
    if (value === 0) push(AppRoutes.ADD_OFFER.replace(':id', offer.id));
    if (value === 2)
      handleOnOfferAdd(
        currentUser,
        offer,
        setIsIdentityModalOpen,
        setIsCompanyModalOpen,
        setLoading,
        setImagesVerificationResults,
        push
      );
    setCurrent(value);

    if (imagesVerificationResults) {
      setShowVerificationModal(true);
    }
  };

  const handleCloseModal = () => setShowVerificationModal(false);

  return (
    <>
      <Steps
        className="mb-4"
        size="small"
        current={current}
        onChange={onChange}
        labelPlacement="vertical"
        items={[
          {
            title: 'Wypełnij formularz',
          },
          {
            title: 'Przejrzyj podgląd',
          },
          {
            title: 'Podpisz',
            icon: loading && current === 2 ? <LoadingOutlined spin rev={undefined} /> : null,
          },
        ]}
      />
      <IdentityModal isIdentityModalOpen={isIdentityModalOpen} />
      <CompanyModal isCompanyModalOpen={isCompanyModalOpen} />
      <ImageVerificationModal
        show={showVerificationModal}
        onClose={handleCloseModal}
        verificationResults={imagesVerificationResults}
        onChangeImage={() => push(AppRoutes.ADD_OFFER.replace(':id', offer.id))}
      />
    </>
  );
};
