import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Row } from 'rsuite';

import { PanelContent, PanelWhite } from '../../../components/Layout/styles';
import { Typography } from '../../../components/Typography/Typography';
import { Offer } from '../../../models/offers.model';

interface Props {
  offer: Offer;
}

export const OfferValidCountingDown: React.FC<Props> = ({ offer }) => {
  const [validTo, setValidTo] = useState<string>(offer.offerValidTo.toDateString());
  const { t } = useTranslation();

  useEffect(() => {
    const interval = setInterval(() => {
      if (!(offer.offerValidTo instanceof Date) || offer.offerValidTo === null || offer.offerValidTo === undefined) {
        setValidTo('brak daty');
        clearInterval(interval);
        return;
      }

      // Oblicz pozostały czas
      const now = new Date();
      const timeDifference = offer.offerValidTo.getTime() - now.getTime();

      // Jeśli czas wygasł, zatrzymaj odliczanie
      if (timeDifference <= 0) {
        clearInterval(interval);
        setValidTo('Oferta nieważna');
        return;
      }

      if (!timeDifference) {
        setValidTo('brak daty');
        clearInterval(interval);
        return;
      }

      // Obliczaj dni, godziny, minuty i sekundy
      const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
      const hours = Math.floor((timeDifference / (1000 * 60 * 60)) % 24);
      const minutes = Math.floor((timeDifference / (1000 * 60)) % 60);
      const seconds = Math.floor((timeDifference / 1000) % 60);

      // Utwórz format odliczania
      const countdownString = `${days}d ${hours}h ${minutes}m ${seconds}s`;

      // Uaktualnij stan z odliczanym czasem
      setValidTo(countdownString);
    }, 100);

    // Wyczyszczenie interwału po zamontowaniu komponentu
    return () => clearInterval(interval);
  }, []);

  return (
    <PanelWhite bodyFill>
      <PanelContent>
        <Grid fluid>
          <Row gutter={16} className="p-4 md:p-2">
            <Typography.H5>
              {t('OFFER_DETAILS.VALID_UNTIL')}: <span style={{ float: 'right' }}>{validTo}</span>
            </Typography.H5>
            <div style={{ marginTop: '8px' }}>
              {t('OFFER_DETAILS.AVAILABILITY')}:
              <span style={{ float: 'right' }}>
                {offer.availability.toLocaleDateString('pl-PL', {
                  day: '2-digit',
                  month: '2-digit',
                  year: 'numeric',
                }) +
                  '-' +
                  (offer.availabilityTo
                    ? offer.availabilityTo.toLocaleDateString('pl-PL', {
                        day: '2-digit',
                        month: '2-digit',
                        year: 'numeric',
                      })
                    : t('OFFER_DETAILS.NO_END_DATE'))}
              </span>
            </div>
          </Row>
        </Grid>
      </PanelContent>
    </PanelWhite>
  );
};
