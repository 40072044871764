import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { SelectOption } from '../../../../models/main.model';

export function useWarehouseEquipment(): SelectOption[] {
  const { t } = useTranslation();

  return useMemo(
    () =>
      [
        'Brak',
        'Separator tłuszczu',
        'Woda na hali',
        'Gniazdo silowe 380V',
        'Gaz',
        'Kanalizacja',
        'Zraszacze PPOZ',
        'Okna dachowe',
        'Klapy oddymowe',
        'Kanał',
        'Tor instalacyjny',
      ].map((item) => ({
        label: t(`FORMS.OFFER_ADD.WAREHOUSE_EQUIPMENT.${item.toUpperCase().replace(/ /g, '_')}`), // Mapowanie tłumaczenia
        value: item,
      })),
    [t]
  );
}
