import { Button, Input, Modal, message } from 'antd';
import React, { useState } from 'react';

import { firebaseFunctions } from '../../../firebase/firebase';
import { FirebaseCallableFunctions } from '../../../firebase/firebase.models';

interface SendWebsiteModalProps {
  isOpen: boolean;
  onClose: () => void;
}

// Domyślna treść emaila - jest to szablon, w którym {websiteLink} zostanie zastąpiony prawdziwym linkiem
const DEFAULT_EMAIL_CONTENT =
  'Zapraszamy do odwiedzenia naszej platformy, gdzie znajdziesz aktualne oferty komercyjnych nieruchomości i sprawdzonych wynajmujących. Możesz bezpiecznie wynająć lokal całkowicie online!';

export const SendWebsiteModal: React.FC<SendWebsiteModalProps> = ({ isOpen, onClose }) => {
  const [email, setEmail] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [content, setContent] = useState<string>(DEFAULT_EMAIL_CONTENT);

  const handleSendWebsite = async () => {
    if (!email) {
      message.error('Please enter an email address');
      return;
    }

    setLoading(true);
    try {
      const resp = await firebaseFunctions.httpsCallable(FirebaseCallableFunctions.CALLER_SEND_WEBSITE)({
        email: email.trim(),
        content: content,
      });

      message.success(resp.data.message);
      setEmail('');
      setContent(DEFAULT_EMAIL_CONTENT);
      onClose();
    } catch (error: any) {
      console.error('Error sending website link:', error);
      message.error(error.message || 'Failed to send website link');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      title="Send Website Link"
      open={isOpen}
      onCancel={onClose}
      width={600}
      footer={[
        <Button key="cancel" onClick={onClose}>
          Cancel
        </Button>,
        <Button key="send" type="primary" loading={loading} onClick={handleSendWebsite}>
          Send
        </Button>,
      ]}>
      <div className="space-y-4">
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">Recipient Email</label>
          <Input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Enter email address"
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">Email Content</label>
          <Input.TextArea
            value={content}
            onChange={(e) => setContent(e.target.value)}
            placeholder="Enter email content"
            rows={4}
          />
          <div className="text-xs text-gray-500 mt-1">
            <p>To write something on a new line, type: {'<br />'}</p>
          </div>
        </div>
      </div>
    </Modal>
  );
};
