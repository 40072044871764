import { Alert, Space } from 'antd';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  deposit: number;
}

export const InfoAboutDeposit: React.FC<Props> = ({ deposit }) => {
  const { t } = useTranslation();
  return (
    <Space direction="vertical" size={12} style={{ marginBottom: 16 }}>
      <Alert
        type="info"
        message={t('DEPOSIT_INFO.MESSAGE', {
          deposit,
        })}
      />
    </Space>
  );
};
