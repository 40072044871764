import { Alert, Button, Modal } from 'antd';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ToastVariant } from '../../../../../components/Toast/toast.model';
import { toastService } from '../../../../../components/Toast/toast.service';
import { Typography } from '../../../../../components/Typography/Typography';
import { firebaseFunctions } from '../../../../../firebase/firebase';
import { FirebaseCallableFunctions } from '../../../../../firebase/firebase.models';
import { useGetOffersByUid } from '../../../../../firebase/hooks/getOffersByUid.hook';
import { OfferStatusState } from '../../../../../models/offers.model';
import { useAuth } from '../../../../Auth/AuthContext';
import { LocalizedLabelWithMultipleLinks } from '../../../../OfferAdd/utils/LocalizedLabelWithMultipleLinks';
import { AppRoutes } from '../../../../Routing/routing.model';

export const DeleteAccount: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { currentUser, signOut } = useAuth();

  const [allOffersInactive, setAllOffersInactive] = useState(true);
  const [offers, loading] = useGetOffersByUid(currentUser.uid);
  const [latestDate, setLatestExpiration] = useState<Date>();

  const [countdown, setCountdown] = useState<string>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { t } = useTranslation();
  const urlParams = new URLSearchParams(window.location.search);
  const isUserLoggedOut = urlParams.get('loggedOut') === 'true';

  useEffect(() => {
    if (isUserLoggedOut) {
      showModal();
      setIsLoading(true);
      const onDeleteAccountConfirm = firebaseFunctions.httpsCallable(
        FirebaseCallableFunctions.ON_DELETE_ACCOUNT_CONFIRM
      );
      onDeleteAccountConfirm({ userUid: currentUser.uid })
        .then(() => {
          toastService.show(t('ACCOUNT_DELETION_CONFIRMATION_EMAIL_SENT'), t('SUCCESS'));
          setIsModalOpen(false);
          setIsLoading(false);
        })
        .catch(() => {
          toastService.show(t('ERROR_DESCRIPTION'), t('ERROR'), { variant: ToastVariant.ERROR });
          setIsLoading(false);
        });
    }
  }, []);

  useEffect(() => {
    console.log('Offers:', offers); //
    const activeOffers = offers?.filter((o) => o.offerStatus.state === OfferStatusState.ACTIVE);
    console.log('Active Offers:', activeOffers);
    const expiration = activeOffers?.map((o) => o.offerValidTo);
    console.log('Expiration Dates:', expiration);
    if (expiration?.length == 1) {
      setLatestExpiration(expiration[0]);
      setAllOffersInactive(false);
    } else if (activeOffers?.length > 1) {
      setAllOffersInactive(false);
      const latestExpiration = expiration?.reduce((latestDate, currentDate) => {
        if (currentDate > latestDate) {
          return currentDate;
        }
        return latestDate;
      });
      setLatestExpiration(latestExpiration);
    } else if (expiration?.length == 0) {
      setAllOffersInactive(true);
    }
  }, [loading]);

  useEffect(() => {
    const interval = setInterval(() => {
      // Oblicz pozostały czas
      const now = new Date();
      const timeDifference = latestDate?.getTime() - now.getTime();

      // Jeśli czas wygasł, zatrzymaj odliczanie
      if (timeDifference <= 0) {
        clearInterval(interval);
      } else {
        // Obliczaj dni, godziny, minuty i sekundy
        const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
        const hours = Math.floor((timeDifference / (1000 * 60 * 60)) % 24);
        const minutes = Math.floor((timeDifference / (1000 * 60)) % 60);
        const seconds = Math.floor((timeDifference / 1000) % 60);

        // Utwórz format daty
        const countdownString = `${days}d ${hours}h ${minutes}m ${seconds}s`;

        // Uaktualnij stan z odliczanym czasem
        setCountdown(countdownString);
      }
    }, 1000);

    // Wyczyszczenie interwału po zamontowaniu komponentu
    return () => clearInterval(interval);
  }, [latestDate]);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    window.history.pushState({}, '', `${AppRoutes.MOJA_TABLICA_ACCOUNT_INFO}/?loggedOut=true`);
    signOut();
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <Alert
        style={{ backgroundColor: 'white' }}
        message={t('DELETING_ACCOUNT')}
        type="error"
        action={
          <Button onClick={showModal} style={{ float: 'right' }}>
            {t('DELETE_ACCOUNT')}
          </Button>
        }
      />

      <Modal
        title={t('DELETE_YOUR_ACCOUNT')}
        open={isModalOpen}
        okButtonProps={{
          style: allOffersInactive ? null : { display: 'none' },
          loading: isLoading,
        }}
        cancelButtonProps={{ disabled: isLoading }}
        onOk={handleOk}
        onCancel={handleCancel}
        okText={t('CONFIRM')}
        cancelText={t('CLOSE')}>
        {allOffersInactive ? (
          <>
            <p>{t('DELETE_ACCOUNT_CONFIRMATION')}</p>
            <p>
              {
                <LocalizedLabelWithMultipleLinks
                  i18nKey="CONTACT_SUPPORT"
                  linkPaths={{
                    '1': `/moja-tablica/advanced/support`,
                  }}
                />
              }
              {/* <Anchor href="/moja-tablica/advanced/support">naszym zespołem obsługi klienta.</Anchor> */}
            </p>{' '}
            <p>{t('CONFIRM_DELETION')}</p>
            <br></br>
            <Typography.H5>{t('RE_LOGIN_REQUIRED')}</Typography.H5>
          </>
        ) : (
          <>
            <p>{t('DELETE_MODAL_APOLOGY')}</p>
            <p>{t('DELETE_MODAL_ACTIVE_OFFER')}</p>
            <p>
              {
                <LocalizedLabelWithMultipleLinks
                  i18nKey="DELETE_MODAL_THANK_YOU"
                  linkPaths={{
                    '1': `/moja-tablica/advanced/support`,
                  }}
                />
              }
              {/* <Anchor href="/moja-tablica/advanced/support">naszym zespołem obsługi klienta.</Anchor> */}
            </p>
            <br></br>
            <Typography.H5>
              {t('DELETE_OFFER_EXPIRY_TIME')} {countdown}
            </Typography.H5>
          </>
        )}
      </Modal>
    </>
  );
};
